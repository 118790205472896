import {IUser} from './iuser';
import {IBase} from './ibase';
import {PRIZE_REVISION_STATUS} from '../constants';
import {IDirectPrize} from './idirect-prize';
import {IPrize} from './iprize';

export enum RevisionState {
  CORRECT = 'correct',
  INCORRECT = 'incorrect',
  FRAUD = 'fraud',
}

export type RecyclePhotosRevision = {
  imageId: string;
  success: boolean;
  invalidReason?: RevisionState;
  url?: string;
};

export type IDirectPrizeParticipation<P extends string = '_'> = IBase & {
  // The user
  user: P extends 'user' ? IUser : string;

  // The associated models
  directPrize: P extends 'directPrize' ? IDirectPrize : string;
  prize?: P extends 'prize' ? IPrize : string;

  // Revision attributes
  revisionStatus: PRIZE_REVISION_STATUS;
  recyclePhotos: RecyclePhotosRevision[];

  isBackupUserPrize?: boolean;
  incentiveGroup?: string;
};
