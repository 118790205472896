import {FindResponse} from '../../defaultActionParams';
import {ICommunity} from '../../models/icommunity';
import {ICrowd} from '../../models/icrowd';
import {ILottery} from '../../models/ilottery';
import {IDirectIncentive} from '../../models/idirect-incentive';
import {CLIENT_ERROR_TYPES, COMMUNITY_REGION_TYPE} from '../../constants';
import {IBase, IDirectPrize} from '../../models';

export type CommunitySimple = {
  _id: string;
  name: string;
  users: number;
  regionType: COMMUNITY_REGION_TYPE;
  featured: boolean;
};

export type CommunityBasic = {
  _id: string;
  name: string;
};

export declare type IIncentiveList = {
  crowds?: ICrowd[];
  lotteries?: ILottery[];
  direct_incentives?: IDirectIncentive[];
  direct_prizes?: IDirectPrize[];
  selectedAncestors?: {
    communities: string;
  }[];
};

export const COMMUNITY_CONTAINERS_FIELDS = (<T extends string[]>(...fields: T) => fields)('_id', 'name', 'regionId', 'created', 'modified');
export type ICommunityContainers = IBase & Pick<ICommunity, typeof COMMUNITY_CONTAINERS_FIELDS[number]>;

export type CommunityBackofficeServiceTypes = {
  list: {
    input: {
      regionType?: COMMUNITY_REGION_TYPE;
      search?: string;
    };
    response: FindResponse<ICommunity>;
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };
  listAll: {
    input: {
      regionType?: COMMUNITY_REGION_TYPE;
      name?: string;
      hasSupply?: boolean;
    };
    response: FindResponse<ICommunityContainers>;
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };
  myCommunities: {
    input: {
      featured?: boolean;
    };
    response: {
      cities: FindResponse<CommunitySimple>;
      counties: FindResponse<CommunitySimple>;
      provinces: FindResponse<CommunitySimple>;
      autonomousCommunities: FindResponse<CommunitySimple>;
      countries: FindResponse<CommunitySimple>;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };
  communitiesReciclos: {
    input: {
      community?: string;
    };
    response: {
      containers_names: string[];
      only_smart_bins_names: string[];
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };
  communities: {
    input: {};
    response: {
      communities: CommunityBasic[];
      others: CommunityBasic[];
      containers: CommunityBasic[];
      smart_bins: CommunityBasic[];
      smart_rings: CommunityBasic[];
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };
  incentiveList: {
    input: {
      active?: boolean;
      userCommunity: string;
      throwCommunities: string[];
      throwDates: string[];
    };
    response: IIncentiveList;
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };
  donationToCrowd: {
    input: {
      crowdId: string;
      reciclosToDonate: number;
    };
    response: {};
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };
  getReciclosDonations: {
    input: {
      communityId?: string;
    };
    response: {};
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };
};
