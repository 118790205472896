import {CLIENT_ERROR_TYPES, COMMUNITY_DESTINATION_STATUS} from '../../constants';
import {ICommunity} from '../../models';

export type recyclingMechanics = {isContainer: boolean; isAvant: boolean; isDoorToDoor: boolean; isSmartBin: boolean; isSmartRing: boolean; isCorporative?: boolean};

export enum CHANGE_TYPE {
  LOCALITY = 'LOCALITY',
  AUTONOMOUS_COMMUNITY = 'AUTONOMOUS_COMMUNITY',
  OUT_OF_SPAIN = 'OUT_OF_SPAIN',
  BACK_TO_SPAIN = 'BACK_TO_SPAIN',
  FIRST_TIME = 'FIRST_TIME',
}

export type CommunityServiceTypes = {
  welcomeValue: {
    input: {
      community: string;
    };
    response: {
      reciclos: number;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };
  hasSmartBins: {
    input: {
      community: string;
    };
    response: {
      hasSmartBins: boolean;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };
  isAvant: {
    input: {
      community: string;
    };
    response: {
      isAvant: boolean;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };

  getRecyclingMechanics: {
    input: {
      community?: ICommunity;
    };
    response: recyclingMechanics;
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };

  getChangeCommunityInfo: {
    input: {
      id: string | null;
      newId: string;
    };
    response:
      | {
          changeType: CHANGE_TYPE.OUT_OF_SPAIN;
          communityStatus: {
            status: COMMUNITY_DESTINATION_STATUS;
            isAvant: boolean;
            isRetreeDisabled: boolean;
            avantIslandMode: boolean;
          };
        }
      | {
          changeType: CHANGE_TYPE.FIRST_TIME;
          newLocalityName: string;
          newLocalityRecyclingMechanics: recyclingMechanics;
          communityStatus: {
            status: COMMUNITY_DESTINATION_STATUS;
            isAvant: boolean;
            isRetreeDisabled: boolean;
            avantIslandMode: boolean;
          };
        }
      | {
          changeType: CHANGE_TYPE.BACK_TO_SPAIN;
          newLocalityName: string;
          newLocalityRecyclingMechanics: recyclingMechanics;
          communityStatus: {
            status: COMMUNITY_DESTINATION_STATUS;
            isAvant: boolean;
            isRetreeDisabled: boolean;
            avantIslandMode: boolean;
          };
        }
      | {
          changeType: CHANGE_TYPE.LOCALITY | CHANGE_TYPE.AUTONOMOUS_COMMUNITY;
          newLocalityName: string;
          newLocalityRecyclingMechanics: recyclingMechanics;
          oldAutonomousCommunityName: string;
          newAutonomousCommunityName: string;
          newAutonomousCommunityRegionId: string;
          communityStatus: {
            status: COMMUNITY_DESTINATION_STATUS;
            isAvant: boolean;
            isRetreeDisabled: boolean;
            avantIslandMode: boolean;
          };
        };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };
};
