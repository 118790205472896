import {IBase} from './ibase';
import {IUser} from './iuser';
import {BARCODE_TYPE, CLIENT_ERROR_TYPES, RECYCLE_PHOTO_CATEGORY, RECYCLE_PHOTO_STATUS} from '../constants';

export const PHOTOS_SIMPLE_FIELDS = (<T extends string[]>(...fields: T) => fields)('_id', 'code', 'status', 'controls', 'barcode', 'created');

export type IRecyclePhoto<P extends string = '_'> = IBase & {
  barcode: string;
  cid: string;
  code: string;
  controls: {
    isNotOthers: boolean;
    isCanOrBottle: boolean;
    isUnique: boolean;
    isSuspiciousUnique: boolean;
    isBarcodeAccepted: boolean;
    isBarcodeNotRepeated: boolean;
  };
  reviewed: {
    isCanOrBottle: boolean;
    isSuspiciousUnique: boolean;
  };
  category: RECYCLE_PHOTO_CATEGORY;
  status: RECYCLE_PHOTO_STATUS;
  user: P extends 'user' ? IUser : string;
  isBlocked?: boolean;
  recoilVersion: number;
};

export type IRecyclePhotoSimple = {
  _id: string;
  cid?: string;
  code: string;
  barcode?: string;
  status: RECYCLE_PHOTO_STATUS;
  url: string;
  errorType?: CLIENT_ERROR_TYPES;
  barcodeType?: BARCODE_TYPE;
  errorData?: any;
  time?: number;
  controls: {
    isNotOthers: boolean;
    isCanOrBottle: boolean;
    isUnique: boolean;
    isSuspiciousUnique: boolean;
    isBarcodeAccepted: boolean;
    isBarcodeNotRepeated: boolean;
  };
  created?: string | Date;
  category?: RECYCLE_PHOTO_CATEGORY;
};

export type IRecyclePhotoWithUrl = IRecyclePhoto & {url?: string};

export type IRecyclePhotoUserBackofficeBucket = {
  id: string;
  modified: string | Date;
  created: string | Date;
  barcode: string;
  category: string;
  url: string;
  status: RECYCLE_PHOTO_STATUS;
};
