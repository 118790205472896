import {USER_ROLE} from '../constants';
import {USER_ACTIONS} from './actions';

export enum ReciclosApplications {
  // Main client extra safe api key
  NoToken = '',
  // Main client extra safe api key
  MainApp = 'TOKEN_EXTRA_SECRET',
  // Backoffice client api key
  BackofficeApp = 'THIS_IS_REALLY_SAFE',
  // Viva marketing api key
  VivaMarketing = 'sDANwuWBUo4vuqDL',
  // CRM api key
  CRM = 'Pum2fas02F1DaKl1',
  // External web api key
  WEB = 'WEBSERVICE_TOKEN_SAFE',
  // Containers app api key
  ContainersApp = 'CONTAINERS_TOKEN_SAFE',
  // Aire app api key
  AireApp = 'AIRE_TOKEN_SAFE',
}

export type PermissionRulesType = {
  [key in USER_ROLE]?: {
    actions: USER_ACTIONS[];
  };
};

export type ServerEndpointsByActionsType = {
  [key in USER_ACTIONS]: string[];
};

export type PermissionApiKeysType = {
  [key in ReciclosApplications]: USER_ACTIONS[];
};
