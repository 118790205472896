import {IUser} from './iuser';
import {IBase} from './ibase';
import {LOCATION_THROW_TYPE, TRANSACTION_STATUS, TRANSACTION_SUBTYPE, TRANSACTION_TYPE} from '../constants';
import {ICommunity} from './icommunity';

export const TRANSACTION_SIMPLE_FIELDS = (<T extends string[]>(...fields: T) => fields)(
  '_id',
  'from',
  'to',
  'value',
  'executed',
  'type',
  'subtype',
  'total',
  'hasChunks',
  'tx',
  'info',
  'circularTrustSecured'
);

export type ITransactionSimple = Pick<ITransaction, typeof TRANSACTION_SIMPLE_FIELDS[number]> & {
  params?: Record<string, any>;
};

export type ITransaction<P extends string = '_'> = IBase & {
  from: P extends 'from' ? IUser : string;
  to: P extends 'to' ? IUser : string;
  type: TRANSACTION_TYPE;
  subtype: TRANSACTION_SUBTYPE;
  value: number;
  tx: string;
  status: TRANSACTION_STATUS;
  total: number;
  executed: string | Date;
  expirationDate?: string | Date;
  info?: any;
  community?: P extends 'community' ? ICommunity : string;
  location: {
    community?: P extends 'community' ? ICommunity : string;
    mocked: boolean;
  };
  applicationLevel?: LOCATION_THROW_TYPE;
  communities?: string[];
  uuid?: string;
  chunkOf?: P extends 'chunkOf' ? ITransaction : string;
  hasChunks?: boolean;
  circularTrustSecured?: boolean;
};

export type IBackofficeReciclosList = {
  _id: string;
  to: string;
  type: TRANSACTION_TYPE;
  status: TRANSACTION_STATUS;
  value: number;
  executed: string | Date;
  community: {
    _id: string;
    name: string;
  };
};
