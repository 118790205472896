export enum SCREENS {
  // Public
  Auth = 'Auth',
  App = 'App',
  AppContainer = 'AppContainer',
  NoVerified = 'NoVerified',
  Error = 'Error',
  MaintenanceScreen = 'MaintenanceScreen',
  UpdateTerms = 'UpdateTerms',
  Landing = 'Landing',

  // Auth
  Welcome = 'Welcome',
  Login = 'Login',
  WelcomeRegister = 'WelcomeRegister',
  RegisterInit = 'Register',
  RegisterStep2 = 'RegisterStep2',
  SelectCommunity = 'SelectCommunity',
  RegisterStep3 = 'RegisterStep3',
  RegisterAccount = 'RegisterAccount',
  RegisterCommunity = 'RegisterCommunity',
  RegisterNameAndTerms = 'RegisterNameAndTerms',
  RecoverPassword = 'RecoverPassword',
  ResetPassword = 'ResetPassword',
  RecoverPasswordSocial = 'RecoverPasswordSocial',
  RecyclingStyle = 'RecyclingStyle',

  // App
  Home = 'Home',
  Recycle = 'Recycle',
  Scan = 'Scan',
  Redeem = 'Redeem',
  Profile = 'Profile',
  Menu = 'Menu',
  Giai = 'Giai',
  LocationRequired = 'LocationRequired',
  GPSRequired = 'GPSRequired',

  //Home
  DetailNews = 'DetailNews',

  // Recycle
  Bucket = 'Bucket',
  BucketMap = 'BucketMap',
  SmartBinMap = 'SmartBinMap',
  SmartBinThrow = 'SmartBinThrow',
  PromotionQrScan = 'PromotionQrScan',
  SmartRingMap = 'SmartRingMap',
  DoorToDoorMap = 'DoorToDoorMap',
  SmartRing2Card = 'SmartRing2Card',

  // Scan
  AcceptedItems = 'AcceptedItems',

  // Redeem
  CrowdDetail = 'CrowdDetail',
  LotteryDetail = 'LotteryDetail',
  ClaimPrize = 'ClaimPrize',
  ClaimValenPrize = 'ClaimValenPrize',
  DirectPrizeDetail = 'DirectPrizeDetail',
  DirectIncentiveCodeTypeDetail = 'DirectIncentiveCodeTypeDetail',
  RetreeTreeName = 'RetreeTreeName',
  MyTrees = 'MyTrees',
  MyParticipations = 'MyParticipations',
  RetreeDirectPrizeParticipation = 'RetreeDirectPrizeParticipation',

  // Reciclos
  Transactions = 'Transactions',

  // Menu
  Info = 'Info',
  InfoContact = 'InfoContact',
  Notifications = 'Notifications',
  Language = 'Language',
  PreDeleteAccount = 'PreDeleteAccount',
  DeleteAccount = 'DeleteAccount',
  CommunicationsPreference = 'CommunicationsPreference',
  PromotionalCodes = 'PromotionalCodes',
  AddPromotionalCode = 'AddPromotionalCode',
  ExchangeListPromotionalCode = 'ExchangeListPromotionalCode',
  EditProfile = 'EditProfile',
  ChangeName = 'ChangeName',
  ChangePassword = 'ChangePassword',
  Mgm = 'Mgm',
  MgmInvitations = 'MgmInvitations',
  Cookies = 'Cookies',
  CookiesPolicy = 'CookiesPolicy',
  PrivacyPolicy = 'PrivacyPolicy',
  TermsOfUse = 'TermsOfUse',
  RetreeDirectPrizeLegalText = 'RetreeDirectPrizeLegalText',
  LoggedOnboarding = 'LoggedOnboarding',
  OnboardingWalkthrough = 'OnboardingWalkthrough',

  // Info Splash
  SplashSmartBinFeedback = 'SplashSmartBinFeedback',

  // Testing
  Testing = 'Testing',
  BLELogs = 'BLELogs',
}
