import {DIRECT_PRIZE_REVISION_INCIDENCE, DIRECT_PRIZE_REVISION_STATUS, DIRECT_PRIZE_STATUS, ILanguage} from '../constants';
import {IIncentive} from './iincentive';
import {IPrize} from './iprize';
import {IDirectPrizeParticipation} from './idirect-prize-participation';

export const DIRECT_PRIZE_SIMPLE_FIELDS_LOGIC_LIST = (<T extends string[]>(...fields: T) => fields)(
  '_id',
  'title',
  'images',
  'imagesBlurhash',
  'stock',
  'start',
  'initialStock',
  'successPhotosToEarn',
  'status',
  'communities',
  'group'
);

// export type IDirectPrizeSimple = Pick<ICrowd, typeof CROWD_SIMPLE_FIELDS[number]> & {image: string};
export type IDirectPrizeSimpleList = Pick<IDirectPrize, typeof DIRECT_PRIZE_SIMPLE_FIELDS_LOGIC_LIST[number]> & {
  image: string;
  imageBlurhash: string;
  prize?: IPrize;
  participation?: IDirectPrizeParticipation;
};

export type IDirectPrize<P extends string = '_'> = IIncentive<P> & {
  title: ILanguage;
  shortDescription: ILanguage;
  description: ILanguage;
  item: ILanguage;
  images: string[];
  imagesBlurhash?: string[];
  secondaryImage?: string;
  secondaryImageBlurhash?: string;

  initialStock: number;
  stock: number;
  outOfStockDate?: string | Date;

  initialBackupStock: number;
  backupStock: number;
  outOfBackupStockDate?: string | Date;

  successPhotosToEarn: number;

  status: DIRECT_PRIZE_STATUS;
  flag: string;

  revisionStatus: DIRECT_PRIZE_REVISION_STATUS;
  revisionIncidence: DIRECT_PRIZE_REVISION_INCIDENCE;
  sentWinners?: boolean;
  vmId?: string;
};

export type ReviewsInfo = {
  pendingReviews: number;
  pendingPhotoReviews: number;
  okPhotoReviews: number;
  koPhotosReviews: number;
};

export type IDirectPrizeExpanded = IDirectPrize & {
  reviewsInfo: ReviewsInfo;
};
