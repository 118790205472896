import {IBase} from './ibase';
import {ILanguage, LOCATION_THROW_TYPE, MODAL_TYPE} from '../constants';
import {SCREENS} from '../screens';

export enum MODAL_TEXT_TYPE {
  Default = 'Default',
}

export enum MODAL_FILTER_TYPE {
  All = 'All',
  Register = 'Register',
  Activity = 'Activity',
}

export type IModal<P extends string = '_'> = IBase & {
  // Active flag
  active: boolean;

  // Modal type
  type: MODAL_TYPE;

  // Audiencia. Que usuarios pueden ver el modal
  filter:
    | {
        // Todos los usuarios
        type: MODAL_FILTER_TYPE.All;
      }
    | {
        // Dependiendo de la comunidad de registro (con ancestors)
        type: MODAL_FILTER_TYPE.Register;
        locations: string[];
      }
    | {
        // Dependiendo de la actividad (comunidad + applicationLevel)
        type: MODAL_FILTER_TYPE.Activity;
        locationThrows: {
          location: string;
          applicationLevel: LOCATION_THROW_TYPE;
        }[];
      };

  // The CTA screen
  ctaStack?: SCREENS;
  ctaScreen?: SCREENS;
  ctaScreenParams?: any;

  // The icon
  icon?: string;

  // Text type
  textType: MODAL_TEXT_TYPE;

  // Indica si despues de ver y cerrar el modal, le vuelve a aparecer o no
  showAlways?: boolean;

  // Prioridad para cuando haya mas de 1 modal al mismo tiempo. Numero mas pequeño, se muestra antes.
  priority: number;

  // Fechas durante las cuales se muestra el modal
  start: string | Date;
  end: string | Date;
};

export type IModalText = IModal & {
  title: ILanguage;
  subtitle?: ILanguage;
  message: ILanguage;
  button?: ILanguage;
};
