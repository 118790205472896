import {SERVER_ERROR_CODES} from '../../constants';
import {BaseServerError} from './BaseServerError';

export class ExceptionError extends BaseServerError {
  constructor(ex: any) {
    super('Exception has Occurred');
    if (ex.code && ex.type) {
      this.code = ex.code;
      this.type = ex.type;
      this.data = ex.data;
    } else {
      this.code = SERVER_ERROR_CODES.EXCEPTION;
      this.type = 'EXCEPTION';
      this.message = ex.message;
      this.stack = ex.stack;
    }
  }
}
