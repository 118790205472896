import {DURATION_TYPE, ILanguage, LOTTERY_CLIENT_STATUS, LOTTERY_COMMUNITY_TYPE, LOTTERY_STATUS, PRIZE_TYPE} from '../constants';
import {IIncentive, IIncentiveHomeFeatured} from './iincentive';

export const LOTTERY_SIMPLE_FIELDS = (<T extends string[]>(...fields: T) => fields)(
  '_id',
  'images',
  'imagesBlurhash',
  'secondaryImage',
  'secondaryImageBlurhash',
  'title',
  'value',
  'ticketValue',
  'lotteryType',
  'communityType',
  'start',
  'end',
  'homeScreenIcon',
  'costToEnable',
  'homeIconStart',
  'popupImage',
  'popupImageExpanded',
  'group',
  'homeScreenPriority',
  'homeScreenTitle',
  'homeScreenSubtitle',
  'homeScreenDescription',
  'homeScreenAction'
);

export const LOTTERY_SIMPLE_FIELDS_LOGIC_LIST = (<T extends string[]>(...fields: T) => fields)(
  '_id',
  'title',
  'value',
  'ticketValue',
  'images',
  'imagesBlurhash',
  'start',
  'end',
  'group',
  'status',
  'communities',
  'nWinners',
  'winners',
  'balance',
  'duration'
);

export type ILotterySimple = Pick<ILottery, typeof LOTTERY_SIMPLE_FIELDS[number]> & {
  clientStatus: LOTTERY_CLIENT_STATUS;
  image: string;
  imageBlurhash: string;
};

export type ILotterySimpleList = Omit<
  Pick<ILottery, typeof LOTTERY_SIMPLE_FIELDS_LOGIC_LIST[number]>,
  'images' | 'imagesBlurhash' | 'end' | 'status' | 'group' | 'winners' | 'balance' | 'duration'
> & {
  clientStatus: LOTTERY_CLIENT_STATUS;
  image: string;
  imageBlurhash: string;
  isWinner: boolean;
  hasClaimed: boolean;
  hasExpired: boolean;
  prizeId?: string;
};

export type ILotteryExpanded = ILottery & {
  clientStatus: LOTTERY_CLIENT_STATUS;
};

export type ILottery<P extends string = '_'> = IIncentive<P> &
  IIncentiveHomeFeatured & {
    title: ILanguage;
    subtitle: ILanguage;
    description: ILanguage;

    value: number;
    ticketValue: number;
    ticketLimit: number;
    balanceFinished: number;
    status: LOTTERY_STATUS;
    lotteryType: number;
    images: string[];
    imagesBlurhash?: string[];
    secondaryImage?: string;
    secondaryImageBlurhash?: string;
    auto: boolean;

    communityType: LOTTERY_COMMUNITY_TYPE;

    prizeType: PRIZE_TYPE;

    address: string;
    ccAddress: string;
    ccVersion: number;

    nWinners: number;

    winners: {
      number: number;
      user: string;
    }[];

    forceCircularChain?: boolean;

    canWinSameUser: boolean;

    duration: DURATION_TYPE;
    spendLimitByUser: number;
    disableCheckEndDate?: boolean;
    costToEnable?: number;
    minimumUserRegistrationDate?: string | Date;
    vmId?: string;
    cleanedNumbers?: boolean;
  };
