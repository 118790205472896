import {AVANT_CONTAINER_CLASSIFICATION, LOTTERY_NUMBER_STATUS} from './constants';
import {IRecyclePhotoSimple} from './models/irecycle-photo';
import {ITree, IUser} from './models';
import {BucketServiceTypes} from 'src/services';

export enum SocketSignals {
  RECYCLE_PHOTO_STATUS_UPDATE = 'RECYCLE_PHOTO_STATUS_UPDATE',
  LOTTERY_NUMBER_STATUS_UPDATE = 'LOTTERY_NUMBER_STATUS_UPDATE',
  NEW_NOTIFICATION = 'NEW_NOTIFICATION',
  USER_UPDATE = 'USER_UPDATE',
  USER_REFRESH = 'USER_REFRESH',
  CROWD_BALANCE_UPDATE = 'CROWD_BALANCE_UPDATE',
  RETREE_UPDATE = 'RETREE_UPDATE',
  BUCKET_THROW_ANALIZED = 'BUCKET_THROW_ANALIZED',
  AVANT_BPO_INVALID = 'AVANT_BPO_INVALID',
}

export type SocketMessages = {
  [SocketSignals.RECYCLE_PHOTO_STATUS_UPDATE]: IRecyclePhotoSimple;
  [SocketSignals.LOTTERY_NUMBER_STATUS_UPDATE]: {
    status: LOTTERY_NUMBER_STATUS;
    numbers: {id: string; number: number}[];
  };
  [SocketSignals.NEW_NOTIFICATION]: void;
  [SocketSignals.USER_REFRESH]: void;
  [SocketSignals.AVANT_BPO_INVALID]: void;
  [SocketSignals.USER_UPDATE]: {
    user: IUser;
  };
  [SocketSignals.CROWD_BALANCE_UPDATE]: {
    crowdId: string;
    balance: number;
  };
  [SocketSignals.RETREE_UPDATE]: {
    tree: ITree;
  };
  [SocketSignals.BUCKET_THROW_ANALIZED]: {
    user: IUser;
    category: AVANT_CONTAINER_CLASSIFICATION;
    clearResponse: BucketServiceTypes['clear']['response'];
    alwaysValid: boolean;
    containerPhoto: string;
  };
};
