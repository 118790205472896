import {ILanguage, RETREE_DIRECT_PRIZE_STATUS} from '../constants';
import {IIncentive, IIncentiveHomeFeatured} from './iincentive';
import {IPrize} from './iprize';
import {IDirectPrizeParticipation} from './idirect-prize-participation';
import {NOTIFICATION_TYPE} from "../notifications";

export const RETREE_DIRECT_PRIZE_SIMPLE_FIELDS_LOGIC_LIST = (<T extends string[]>(...fields: T) => fields)(
  '_id',
  'title',
  'images',
  'imagesBlurhash',
  'stock',
  'start',
  'status',
  'communities',
  'group'
);

// export type IDirectPrizeSimple = Pick<ICrowd, typeof CROWD_SIMPLE_FIELDS[number]> & {image: string};
export type IRetreeDirectPrizeSimpleList = Pick<IRetreeDirectPrize, typeof RETREE_DIRECT_PRIZE_SIMPLE_FIELDS_LOGIC_LIST[number]> & {
  image: string;
  imageBlurhash: string;
  //TODO cal??? prize - participation
  prize?: IPrize;
  participation?: IDirectPrizeParticipation;
};

export enum RetreeDirectPrizeFeaturedStage {
  NONE = 'NONE',
  PREVIOUS = 'PREVIOUS',
  ONGOING = 'ONGOING',
  AFTERWARD = 'AFTERWARD',
  NOT_COMPLETED = 'NOT_COMPLETED',
}

export type NotificationsRetreeDirectPrize = {
    type: NOTIFICATION_TYPE.RETREE_DIRECT_PRIZE_1 | NOTIFICATION_TYPE.RETREE_DIRECT_PRIZE_2 | NOTIFICATION_TYPE.RETREE_DIRECT_PRIZE_3 | NOTIFICATION_TYPE.RETREE_DIRECT_PRIZE_4 |
        NOTIFICATION_TYPE.RETREE_DIRECT_PRIZE_5 | NOTIFICATION_TYPE.RETREE_DIRECT_PRIZE_6;
    date: Date;
    enabled: boolean;
}

export type TreeStagesWithActiveFlag = {
  planting: {
    start: Date;
    txValue: number;
    timeStampToActive: number;
  };
  fertilizing: {
    start: Date;
    txValue: number;
    timeStampToActive: number;
  };
  watering: {
    start: Date;
    txValue: number;
    timeStampToActive: number;
  };
  transplanting: {
    start: Date;
    txValue: number;
    timeStampToActive: number;
  };
};

export type IRetreeDirectPrize<P extends string = '_'> = IIncentive<P> &
  IIncentiveHomeFeatured & {
    title: ILanguage;
    shortDescription: ILanguage;
    description: ILanguage;
    item: ILanguage;
    images: string[];
    imagesBlurhash?: string[];
    secondaryImage?: string;
    secondaryImageBlurhash?: string;
    stock: number;
    extraPotentialsWinners: number;
    status: RETREE_DIRECT_PRIZE_STATUS;
    onGoingStartDate: Date; // -> 01/10/24  00:00  al
    onGoingEndDate: Date; // -> 07/10/2024  00:00  ó hasta que el usuario finalize la participacion (lo que ocurra primero)
    treeStages: {
      planting: {
        start: Date;
        txValue: number;
      };
      fertilizing: {
        start: Date;
        txValue: number;
      };
      watering: {
        start: Date;
        txValue: number;
      };
      transplanting: {
        start: Date;
        txValue: number;
      };
    };
    notifications: NotificationsRetreeDirectPrize[];
    // sentWinners?: boolean; Participation
    // TODO decidir que fer
    // revisionStatus: DIRECT_PRIZE_REVISION_STATUS;
    // revisionIncidence: DIRECT_PRIZE_REVISION_INCIDENCE;
  };

export type IRetreeDirectPrizeResponse = IRetreeDirectPrize & {
  treeStages: TreeStagesWithActiveFlag;
};

/*
export type ReviewsInfo = {
  pendingReviews: number;
  pendingPhotoReviews: number;
  okPhotoReviews: number;
  koPhotosReviews: number;
};

export type IDirectPrizeExpanded = IDirectPrize & {
  reviewsInfo: ReviewsInfo;
};
*/
