import {
  CLAIM_RECYCLE_PHOTO_RESULT,
  CLIENT_ERROR_TYPES,
  CONTAINER_SHAPE_RESULT,
  DOOR_TO_DOOR_DEPOSIT_PHOTO_RESULT,
  DOOR_TO_DOOR_DEPOSIT_TYPE,
  DUPLICATE_RECYCLE_PHOTO_RESULT,
  HUPI_TYPE_IMAGE,
  OTHER_CONTAINER_TYPE,
} from '../../constants';
import {IBucketThrow, IClaimRecyclePhotoItem, IDirectPrizeParticipation, IDuplicateRecyclePhotoItem, RecyclePhotosRevision} from '../../models';

export enum OTHERS_REASON {
  INCORRECT = 'incorrect',
  FRAUD = 'fraud',
}

export type IHupiPhoto = {
  _id: string;
  image_id: string;
  type_provider: string;
  idFdh: number;
  created: Date;
  coincidence_percentage?: number;
  type_hupi?: string;
  type_bo?: string;
};

export type RevisionBackofficeServiceTypes = {
  validateAndGetClaim: {
    input: {
      id: string | null;
      result: CLAIM_RECYCLE_PHOTO_RESULT;
      othersReason?: OTHERS_REASON;
    };
    response: {
      success: boolean;
      next: IClaimRecyclePhotoItem | null;
      pending: number;
      checkedIncorrect: number;
      fraudThisMonth: number;
      checkedCurrentMonthPhotos: number;
      checkedLastMonthPhotos: number;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };
  validateAndGetDuplicate: {
    input: {
      id: string | null;
      result: DUPLICATE_RECYCLE_PHOTO_RESULT;
    };
    response: {
      success: boolean;
      next: IDuplicateRecyclePhotoItem | null;
      pending: number;
      checkedMonthly: number;
      duplicatedMonthly: number;
      incorrectMonthly: number;
      checkedLastMonthPhotos: number;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };
  checkDoorToDoorAndNext: {
    input: {
      id: string | null;
      result: DOOR_TO_DOOR_DEPOSIT_PHOTO_RESULT;
      depositType?: DOOR_TO_DOOR_DEPOSIT_TYPE;
      invalidReason?: OTHERS_REASON;
    };
    response: {
      success: boolean;
      next: IBucketThrow | null;
      pending: number;
      pendingLast24Hours: number;
      checkedLast24Hours: number;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };
  checkAvantPhotoAndNext: {
    input: {
      id: string | null;
      avantContainerType: CONTAINER_SHAPE_RESULT | null;
      otherContainer?: OTHER_CONTAINER_TYPE | null;
    };
    response: {
      success: boolean;
      next: IBucketThrow | null;
      pending: number;
      pendingLast24Hours: number;
      checkedLast24Hours: number;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };
  updateAvantPhotosChecked: {
    input: {
      ids: string[] | null;
      avantContainerType: CONTAINER_SHAPE_RESULT | null;
      otherContainer?: OTHER_CONTAINER_TYPE | null;
    };
    response: {
      success: boolean;
      pending: number;
      pendingLast24Hours: number;
      checkedLast24Hours: number;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };
  getAvantPhotos: {
    input: {
      numberOfPhotos: number;
    };
    response: {
      avantPhotos: IBucketThrow[] | null;
      isLastPhoto: boolean;
      pending: number;
      pendingLast24Hours: number;
      checkedLast24Hours: number;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };
  validateAndGetDirectPrizeWinner: {
    input: {
      id: string | null;
      directPrizeId: string;
      returnedPrize: 'current' | 'next' | 'previous';
      phone?: string;
      submitResult?: boolean;
      result?: RecyclePhotosRevision[];
    };
    response: {
      success: boolean;
      next: IDirectPrizeParticipation<'user'>;
      showPrevious: boolean;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION | CLIENT_ERROR_TYPES.VIVA_MARKETING_API_ERROR | CLIENT_ERROR_TYPES.NOT_FOUND;
  };
  addBackupUsers: {
    input: {
      id: string;
      amount: number;
    };
    response: {
      success: boolean;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION | CLIENT_ERROR_TYPES.NOT_FOUND;
  };
  checkHupiPhotoAndNext: {
    input: {
      id: string | null;
      // fdhToken?: string;
      hupiImageType: HUPI_TYPE_IMAGE | null;
    };
    response: {
      success: boolean;
      next?: IHupiPhoto & {image_blob: string};
      tokens?: {sayme: string; retailTools: string};
      pending?: number;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };
};
