import {EXTERNAL_CALLS_IDENTIFIER, EXTERNAL_CALLS_STATUS} from '../constants';
import {IBase} from './ibase';

/**
 * Enums
 */

export enum AXIOS_HTTP_METHOD {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum AXIOS_PARAM_TYPE {
  STRING = 'STRING',
  STRING_ARRAY = 'STRING_ARRAY',
  NUMBER = 'NUMBER',
  NUMBER_ARRAY = 'NUMBER_ARRAY',
  OBJECT = 'OBJECT',
  FILE = 'FILE',
  FILE_URL = 'FILE_URL',
}

export enum AXIOS_PARAMS_TYPE {
  Query = 'Query',
  Json = 'Json',
  FormData = 'FormData',
  Soap = 'Soap',
}

/**
 * Types
 */

export type IPetition<T = any> = {
  type: EXTERNAL_CALLS_IDENTIFIER;
  code: number;
  time: number;
  message: string;
  data?: T;
  created?: string | Date;
};

export type IAxiosParam =
  | {
      name: string;
      value: string;
      type: AXIOS_PARAM_TYPE.STRING;
    }
  | {
      name: string;
      value: number;
      type: AXIOS_PARAM_TYPE.NUMBER;
    }
  | {
      name: string;
      value: any;
      type: AXIOS_PARAM_TYPE.OBJECT;
    }
  | {
      name: string;
      value: string;
      type: AXIOS_PARAM_TYPE.FILE_URL;
    }
  | {
      name: string;
      value: any;
      type: AXIOS_PARAM_TYPE.FILE;
    }
  | {
      name: string;
      value: number[];
      type: AXIOS_PARAM_TYPE.NUMBER_ARRAY;
    }
  | {
      name: string;
      value: string[];
      type: AXIOS_PARAM_TYPE.STRING_ARRAY;
    };

export type IAxios = {
  method: AXIOS_HTTP_METHOD;
  url: string;
  params?: IAxiosParam[];
  paramsJson?: Record<string, any>;
  paramsType: AXIOS_PARAMS_TYPE;
};

export type IExternalCall<P extends string = '_'> = IBase & {
  name: EXTERNAL_CALLS_IDENTIFIER;
  status: EXTERNAL_CALLS_STATUS;
  priority: number;
  uniqueKey: string;
  axios: IAxios;
  params?: Record<string, any>;
  petitions: IPetition[];
  queued: string | Date;
};
