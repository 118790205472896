import {HTML_ERROR_CODES} from '../../constants';

export class HtmlError extends Error {
  code: HTML_ERROR_CODES;
  message: string;

  constructor(code: HTML_ERROR_CODES = HTML_ERROR_CODES.BASE_HTML, message?: string) {
    super('');
    this.code = code;
    this.message = message;
  }
}
