import {IUser} from '../../models/iuser';
import {AVAILABLE_LANGUAGES_TYPE, CLIENT_ERROR_TYPES, LOGIN_TYPE} from '../../constants';
import {FakeSms} from '../../api';
import {ICommunity} from '../../models';

export enum ACCESS_TOKEN_STATUS {
  INVALID = 'INVALID',
  VALID = 'VALID',
  EXPIRED = 'EXPIRED',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  IN_MAINTENANCE = 'IN_MAINTENANCE',
  PENDING_CC_TOKENIZATION = 'PENDING_CC_TOKENIZATION',
  PROFILING_NOT_ANSWERED = 'PROFILING_NOT_ANSWERED',
}

export type AuthServiceTypes = {
  status: {
    input: {
      debug?: boolean;
    };
    response: string;
    error: never;
  };
  islandMode: {
    input: never;
    response: boolean;
    error: never;
  };
  login: {
    input: {
      phone: string;
      password: string;
    };
    response: {
      access_token?: string;
      refresh_token?: string;
      user?: IUser<'location'>;
      status: ACCESS_TOKEN_STATUS;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION | CLIENT_ERROR_TYPES.CREDENTIALS | CLIENT_ERROR_TYPES.BANNED;
  };
  loginAire: {
    input: {
      phone: string;
      password: string;
    };
    response: {
      access_token?: string;
      refresh_token?: string;
      user?: IUser;
      status: ACCESS_TOKEN_STATUS;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION | CLIENT_ERROR_TYPES.CREDENTIALS | CLIENT_ERROR_TYPES.BANNED;
  };
  token: {
    input: {
      grant_type: string;
      refresh_token: string;
    };
    response: {
      access_token: string;
      token_type: string;
      expires_in: number;
      refresh_token: string;
    };
    error: CLIENT_ERROR_TYPES.OAUTH2;
  };
  loginEmail: {
    input: {
      email: string;
      password: string;
    };
    response: {
      access_token?: string;
      user?: IUser<'location'>;
      status: ACCESS_TOKEN_STATUS;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION | CLIENT_ERROR_TYPES.CREDENTIALS | CLIENT_ERROR_TYPES.BANNED;
  };
  loginGoogle: {
    input: {
      googleId: string;
    };
    response: {
      status: ACCESS_TOKEN_STATUS;
      access_token?: string;
      refresh_token?: string;
      user?: IUser<'location'>;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION | CLIENT_ERROR_TYPES.CREDENTIALS;
  };
  loginAireGoogle: {
    input: {
      googleId: string;
    };
    response: {
      status: ACCESS_TOKEN_STATUS;
      access_token?: string;
      refresh_token?: string;
      user?: IUser;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION | CLIENT_ERROR_TYPES.CREDENTIALS;
  };
  loginApple: {
    input: {
      appleId: string;
      isIos: boolean;
    };
    response: {
      status: ACCESS_TOKEN_STATUS;
      access_token?: string;
      refresh_token?: string;
      user?: IUser<'location'>;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION | CLIENT_ERROR_TYPES.CREDENTIALS | CLIENT_ERROR_TYPES.INVALID_APPLE_ID;
  };
  loginFacebook: {
    input: {
      userId: string;
    };
    response: {
      status: ACCESS_TOKEN_STATUS;
      access_token?: string;
      user?: IUser<'location'>;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION | CLIENT_ERROR_TYPES.CREDENTIALS | CLIENT_ERROR_TYPES.INVALID_APPLE_ID;
  };
  loginAireFacebook: {
    input: {
      userId: string;
    };
    response: {
      status: ACCESS_TOKEN_STATUS;
      access_token?: string;
      user?: IUser<'location'>;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION | CLIENT_ERROR_TYPES.CREDENTIALS | CLIENT_ERROR_TYPES.INVALID_APPLE_ID;
  };
  check: {
    input: {
      access_token: string;
    };
    response: {
      status: ACCESS_TOKEN_STATUS;
      user?: IUser<'location'>;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION;
  };
  me: {
    input: {};
    response: {
      user: IUser<'location'>;
    };
    error: never;
  };
  isValid: {
    input: {
      phone?: string;
      email?: string;
      loginType?: LOGIN_TYPE;
    };
    response: {
      phone?: boolean;
      email?: boolean;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION | CLIENT_ERROR_TYPES.PHONE_EXISTS | CLIENT_ERROR_TYPES.PHONE_INVALID;
  };
  register: {
    input: {
      phone: string;
      password?: string;
      name: string;
      community: string;
      acceptMailing: boolean;
      agreeTerms: boolean;
      agreePrivatePolicy: boolean;
      recycleContainers: string;
      referrer?: string;
      googleId?: string;
      appleId?: string;
      facebookId?: string;
      isIos?: boolean;
      iOSVersion?: string | null;
      isAndroid?: boolean;
      androidVersion?: string | null;
      email?: string;
      photo?: string;
      throwId?: string;
      machineId?: string;
      sTime?: string;
      language: AVAILABLE_LANGUAGES_TYPE;
      captchaToken?: string;
      captchaV2Token?: string;
      utm_content?: string;
      utm_campaign?: string;
      utm_medium?: string;
      utm_source?: string;
      utm_external?: string;
      userAire?: boolean;
    };
    response: {
      user: IUser;
      access_token: string;
      refresh_token: string;
      location: ICommunity;
      sms?: FakeSms;
      reCaptchaScore?: number;
    };
    error:
      | CLIENT_ERROR_TYPES.MOLECULER_VALIDATION
      | CLIENT_ERROR_TYPES.PHONE_EXISTS
      | CLIENT_ERROR_TYPES.PASSWORD_MISSING
      | CLIENT_ERROR_TYPES.INVALID_BIRTH_DATE
      | CLIENT_ERROR_TYPES.COMMUNITY_NOT_EXISTS
      | CLIENT_ERROR_TYPES.INVALID_ACCEPT_VALUE
      | CLIENT_ERROR_TYPES.INVALID_APPLE_ID
      | CLIENT_ERROR_TYPES.RECAPTCHA_NEEDED
      | CLIENT_ERROR_TYPES.RECAPTCHA_MISSING;
  };
  registerUserAire: {
    input: {
      phone: string;
      password?: string;
      name: string;
      surname: string;
      community: string;
      acceptMailing: boolean;
      agreeTerms: boolean;
      agreePrivatePolicy: boolean;
      googleId?: string;
      facebookId?: string;
      isIos?: boolean;
      email?: string;
      language: AVAILABLE_LANGUAGES_TYPE;
      captchaToken?: string;
      captchaV2Token?: string;
    };
    response: {
      user: IUser;
      access_token: string;
      refresh_token: string;
      location: ICommunity;
      sms?: FakeSms;
      reCaptchaScore?: number;
    };
    error:
      | CLIENT_ERROR_TYPES.MOLECULER_VALIDATION
      | CLIENT_ERROR_TYPES.PHONE_EXISTS
      | CLIENT_ERROR_TYPES.PASSWORD_MISSING
      | CLIENT_ERROR_TYPES.INVALID_ACCEPT_VALUE
      | CLIENT_ERROR_TYPES.RECAPTCHA_NEEDED
      | CLIENT_ERROR_TYPES.RECAPTCHA_MISSING;
  };
  registerUserContainer: {
    input: {
      name: string;
      surname: string;
      email: string;
      password: string;
      agreeTerms: boolean;
      agreePolicy: boolean;
      googleId?: string;
    };
    response: {
      user: IUser;
      access_token: string;
    };
    error:
      | CLIENT_ERROR_TYPES.MOLECULER_VALIDATION
      | CLIENT_ERROR_TYPES.EMAIL_EXISTS
      | CLIENT_ERROR_TYPES.PASSWORD_MISSING
      | CLIENT_ERROR_TYPES.EMAIL_INVALID
      | CLIENT_ERROR_TYPES.INVALID_ACCEPT_VALUE;
  };
  forgotPassword: {
    input: {
      phone: string;
    };
    response: {
      success: boolean;
      sms?: FakeSms;
    };
    error:
      | CLIENT_ERROR_TYPES.MOLECULER_VALIDATION
      | CLIENT_ERROR_TYPES.INVALID_PHONE_NUMBER
      | CLIENT_ERROR_TYPES.USER_REGISTERED_WITH_SOCIAL_NETWORK
      | CLIENT_ERROR_TYPES.MINUTES_15_INTERVAL;
  };
  forgotPasswordAire: {
    input: {
      phone: string;
    };
    response: {
      success: boolean;
      sms?: FakeSms;
    };
    error:
      | CLIENT_ERROR_TYPES.MOLECULER_VALIDATION
      | CLIENT_ERROR_TYPES.INVALID_PHONE_NUMBER
      | CLIENT_ERROR_TYPES.USER_REGISTERED_WITH_SOCIAL_NETWORK
      | CLIENT_ERROR_TYPES.MINUTES_15_INTERVAL;
  };
  validate: {
    input: {
      token: string;
    };
    response: {
      user: IUser<'location'>;
      fused: boolean;
      numberOfInitialReciclos?: number;
      canReceiveFreeTicket?: boolean;
      isGoogle: boolean;
      referrerToken?: string;
      utm_external?: string;
      access_token?: string;
      refresh_token?: string;
    };
    error: CLIENT_ERROR_TYPES.PHONE_EXISTS | CLIENT_ERROR_TYPES.INVALID_VERIFY_TOKEN | CLIENT_ERROR_TYPES.EXPIRED_VERIFY_TOKEN;
  };
  validateAire: {
    input: {
      token: string;
    };
    response: {
      user: IUser<'location' | 'communities'>;
      fused: boolean;
      isGoogle: boolean;
      referrerToken?: string;
      access_token?: string;
      refresh_token?: string;
    };
    error: CLIENT_ERROR_TYPES.PHONE_EXISTS | CLIENT_ERROR_TYPES.INVALID_VERIFY_TOKEN | CLIENT_ERROR_TYPES.EXPIRED_VERIFY_TOKEN;
  };
  resendVerificationSms: {
    input: {};
    response: {
      success: boolean;
      sms?: FakeSms;
    };
    error: CLIENT_ERROR_TYPES.ALREADY_VALIDATED | CLIENT_ERROR_TYPES.MINUTES_15_INTERVAL;
  };
  resendVerificationSmsAire: {
    input: {};
    response: {
      success: boolean;
      sms?: FakeSms;
    };
    error: CLIENT_ERROR_TYPES.ALREADY_VALIDATED | CLIENT_ERROR_TYPES.MINUTES_15_INTERVAL;
  };
  resetPassword: {
    input: {
      password: string;
      recoverToken: string;
    };
    response: {
      success: boolean;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION | CLIENT_ERROR_TYPES.INVALID_RECOVER_TOKEN;
  };
  resetPasswordAire: {
    input: {
      password: string;
      recoverToken: string;
    };
    response: {
      success: boolean;
    };
    error: CLIENT_ERROR_TYPES.MOLECULER_VALIDATION | CLIENT_ERROR_TYPES.INVALID_RECOVER_TOKEN;
  };
};
