import {IBase} from './ibase';
import {IIncentive} from './iincentive';
import {IUser} from './iuser';

export enum INCENTIVE_PRIZE_CODE_STATUS {
  PENDING = 0, // No se ha dado este código a ningún usuario
  REWARDED = 1, // Código dado a un usuario
  EXCHANGED = 2, // El usuario ha canjeado el código en el sistema externo (solo si lo podemos saber)
}

export type IIncentivePrizeCode<P extends string = '_'> = IBase & {
  code: string;
  status: INCENTIVE_PRIZE_CODE_STATUS;
  user?: P extends 'user' ? IUser : string;
  incentive: P extends 'incentive' ? IIncentive : string;
  incentiveModel: string;
};
