import {ICommunity} from './icommunity';
import {CROWD_INCENTIVES_ORDER, DIRECT_INCENTIVES_ORDER, ILanguage, LOCATION_THROW_TYPE, LOTTERY_INCENTIVES_ORDER} from '../constants';
import {IBase} from './ibase';
import {IUser} from './iuser';

export type ICommunityApplication = {
  community: string | ICommunity;
  applicationLevel: LOCATION_THROW_TYPE[];
  order?: LOTTERY_INCENTIVES_ORDER | DIRECT_INCENTIVES_ORDER | CROWD_INCENTIVES_ORDER;
};

export enum HOME_SCREEN_ACTION {
  LIST = 'LIST',
  ITEM = 'ITEM',
}

export type IIncentiveHomeFeatured = {
  popupImage?: ILanguage;
  popupImageExpanded?: boolean;
  homeScreenTitle?: ILanguage;
  homeScreenSubtitle?: ILanguage;
  homeScreenDescription?: ILanguage;
  homeScreenFeatured?: boolean;
  homeScreenIcon?: string;
  homeScreenAction?: HOME_SCREEN_ACTION;
  homeScreenPriority?: number;
  homeIconStart?: string | Date;
  homeIconEnd?: string | Date;
};

export type IIncentive<P extends string = '_'> = IBase & {
  start: string | Date;
  end: string | Date;
  communities: P extends 'communities' ? ICommunity[] : string[]; // | {_id: string; name: string}[]
  priority: boolean;
  category: string;
  special: boolean;
  balance: number;
  archived: boolean;
  group?: string;
  hasCcBalance?: boolean;
  creator?: P extends 'creator' ? IUser : string;
};
