import {IUser} from './iuser';
import {IBase} from './ibase';
import {RETREE_DIRECT_PRIZE_PARTICIPATION_REVISION_STATUS, TRANSACTION_TYPE} from '../constants';
import {IPrize} from './iprize';
import {IRetreeDirectPrize} from './iretree-direct-prize';

export enum RevisionStateParticipation {
  CORRECT = 'correct',
  INCORRECT = 'incorrect',
  FRAUD = 'fraud',
}

export enum RevisionStateMotivation {
  MOCKED_POSITION = 'MOCKED_POSITION',
  INVALID_BUCKET = 'INVALID_BUCKET',
  FRAUD_BUCKET = 'FRAUD_BUCKET',
  FRAUD_PHOTO = 'FRAUD_PHOTO',
  INVALID_PHOTO = 'INVALID_PHOTO',
}

export type RecyclePhotosParticipationRevision = {
  imageId: string;
  success: boolean;
  invalidReason?: RevisionStateParticipation;
  url?: string;
};

export type IRetreeDirectPrizeParticipation<P extends string = '_'> = IBase & {
  // The user

  counter: number;
  user: P extends 'user' ? IUser : string;

  // The associated models
  retreeDirectPrize?: P extends 'retreeDirectPrize' ? IRetreeDirectPrize : string;
  prize?: P extends 'prize' ? IPrize : string;

  // TODO Cuando se marca invalido / valido en BPO se alamacena para cada
  // TODO checkbox el motivo y IDs que corresponden (ej: 5 fotos invalidas de diferentes lanzamientos)
  // TODO plantear si añadir url
  revisionStatus: RETREE_DIRECT_PRIZE_PARTICIPATION_REVISION_STATUS;

  bpoDecision: {
    type: RevisionStateMotivation;
    ids?: string[];
  }[];
  lastTreeTransplanted: string | Date;
  isPopulated: boolean;
  isBlockedRevision: boolean;
  // TODO Review si bucket Throw tira tot igual o DoorTODoor canvia
  transactionRevision?: {
    type: TRANSACTION_TYPE;
    value: number;
    ids: string[];
    data?: {
      bucketId?: string;
      bucketThrowDetails?: RecyclePhotosParticipationRevision & {
        containerDistance: number;
        throwType: string;
        isMocked: boolean;
      };
      photoData?: RecyclePhotosParticipationRevision[];
    }[];
  }[];
};
