import {CLIENT_ERROR_CODES, CLIENT_ERROR_TYPES} from '../../constants';
import {ValidationError} from 'fastest-validator';
import {BaseClientError} from './BaseClientError';

export class MoleculerValidationError extends BaseClientError {
  constructor(message: string | ValidationError[], field?: string, ctx?: any) {
    let data;
    if (typeof message === 'string') {
      data = [
        {
          type: 'invalidValue',
          message: `The '${field}' field ${message}.`,
          field,
          actual: ctx.params[field],
          nodeID: ctx.broker.nodeID,
          action: ctx.action.name,
        },
      ];
    } else {
      data = message;
    }
    super(CLIENT_ERROR_CODES.MOLECULER_VALIDATION, CLIENT_ERROR_TYPES.MOLECULER_VALIDATION, data);
  }
}
