// Containers App Screens
export enum CONTAINERS_SCREENS {
  ContainersList = 'ContainersList',
  ContainersMap = 'ContainersMap',
  Login = 'Login',
  Home = 'Home',
  HelpAndSupport = 'HelpAndSupport',
  PrivacyPolicy = 'PrivacyPolicy',
  ContainerDetails = 'ContainerDetails',
  MainMenu = 'MainMenu',
  SetContainerLocation = 'SetContainerLocation',
  ReportContainerIncidence = 'ReportContainerIncidence',
  Scan = 'Scan',
  ConfirmPhoto = 'ConfirmPhoto',
  Confirmation = 'Confirmation',
  ManualActivation = 'ManualActivation',
  Welcome = 'Welcome',
  Register = 'Register',
  TermsOfUse = 'TermsOfUse',
}
