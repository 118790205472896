import {ICommunity} from './icommunity';
import {IBase} from './ibase';
import {
  AVAILABLE_LANGUAGES_TYPE,
  COMMUNICATION_CHANNEL,
  COMMUNICATION_TYPE,
  HOME_STATUS,
  LOCATION_THROW_TYPE,
  MODEL_NAME,
  SMART_RING_CATEGORY,
  USER_CATEGORY,
  USER_ROLE,
  USER_STATUS,
} from '../constants';

export const REFERRED_USER_SIMPLE_FIELDS = (<T extends string[]>(...fields: T) => fields)('name', 'avatarImage', 'avatarUrl');

export type IReferredUser = Pick<IUser, typeof REFERRED_USER_SIMPLE_FIELDS[number]>;

export type CommunicationPermissions = {
  [key in COMMUNICATION_CHANNEL]: {
    [key in COMMUNICATION_TYPE]: boolean;
  };
};

export enum EXPIRATION_STATUS {
  NOT_EXPIRED = 'NOT_EXPIRED',
  BALANCE_EXPIRED = 'BALANCE_EXPIRED',
  EXPIRATION_COMPLETED = 'EXPIRATION_COMPLETED',
}

export type IExpirationBalances = {
  expirationDate: Date;
  balance: number;
  status: EXPIRATION_STATUS;
};

export type ILocationThrow = {
  from: string | Date;
  until: string | Date;
  last: string | Date;
  location: string;
  locationAncestors: Array<string>;
  type: LOCATION_THROW_TYPE;
};

export type IUserMarketplace = {
  community: string;
  ancestors: Array<string>;
};

export type IUserSensitiveData = {
  status: USER_STATUS;
  name: string | null;
  surname: string | null;
  'local.phone': string | null;
  'local.password': string | null;
  email: string | null;
  avatarImage: string | null;
  verifiedToken: string | null;
  smsNotifications: boolean;
  referrerToken: string | null;
  deletedById: string | null;
  subscription: string[];
  google: any;
  facebook: any;
  hash: any;
  apple: any;
  banned?: boolean;
  bannedDate?: Date | string;
  smartRingCard?: string;
  userAire?: boolean;
  currentMarketplace: IUserMarketplace | null;
};

export type IIncentiveParticipation = {
  incentiveId: string; // | IMicroIncentive | ILottery | IDirectIncentive | ICrowd,
  incentiveModel: MODEL_NAME;
  community: string;
  group?: string;
};

export type PromotionParticipation = {
  promotionId: string;
  date: string | Date;
};

export type RmfSegmentation = {
  grupo: string;
  control_group: boolean;
  valid: boolean;
  model_date: Date | string;
};

// export type IUser = IBase & {
export type IUser<P extends string = '_'> = IBase & {
  name: string;
  surname?: string | null;
  email: string;
  lat: number;
  lng: number;
  avatarImage: string;
  avatarUrl: string;
  status: USER_STATUS;
  role: USER_ROLE[];
  balance: number;
  firstTime: boolean;
  weeklyLimitReached: boolean;
  language: AVAILABLE_LANGUAGES_TYPE;
  birthDate?: string | Date;

  hasCcBalance?: boolean;
  smsNotifications: boolean;
  lastSmsSend: string | Date;

  lastCrmSync: string | Date;

  lastLogin: string | Date;

  lastLoginApp: string | Date;

  validated: string | Date;

  communities: P extends 'communities' ? ICommunity[] : string[];

  location: P extends 'location' ? ICommunity : string;
  locationAncestors: P extends 'locationAncestors' ? ICommunity[] : string[];
  locationThrows: ILocationThrow[];

  useApp: boolean;
  android: boolean;
  androidVersion: string;
  ios: boolean;
  iosVersion: string;

  local: {
    phone: string;
  };

  terms: {
    agreeTerms?: boolean; // upToDate (dependiendo si ha aceptado las ultimas, se marca a true o false) (mediante un CRONJOB)
    agreePrivatePolicy?: boolean; // upToDate
    acceptCommercialInfo?: boolean;
    acceptMailing: boolean;
  };

  flagCheckTerms?: Date;

  home: {
    status: HOME_STATUS;
  };

  userCategory: USER_CATEGORY;
  smartRingCategory: SMART_RING_CATEGORY;

  utm: {
    content?: string | null;
    campaign?: string | null;
    medium?: string | null;
    source?: string | null;
    external?: string | null;
  };

  statistics: {
    reciclosEarned: number;
    reciclosSpent: number;
    scannedPackaging: number;
    lotteriesPlayed: number;
    crowdsDonated: number;
    reciclosEarnedThisWeek: number;
    reciclosSpentThisWeek: number;
    scannedPackagingThisWeek: number;
    mgm: number;
  };

  profiling: {
    recycleContainers: {
      paper: boolean;
      rest: boolean;
      plastic: boolean;
      glass: boolean;
      organic: boolean;
      no_recycle: boolean;
    };
    recycleContainersAnswered: boolean;
  };

  subscription: string[];

  incentiveParticipations: IIncentiveParticipation[];
  promotionParticipations: PromotionParticipation[];

  banned: boolean;
  bannedDate: Date | string;
  smartRingCard: string;
  smartRingCardDate: Date | string;
  bleId: string;
  bleIdDate: Date | string;

  expirationBalances: IExpirationBalances[];

  smartRingTestUser: {
    startDate: Date | string;
    endDate: Date | string;
    endReason: string;
  };

  currentMarketplace?: IUserMarketplace;

  reCaptcha?: {
    success: boolean;
    challenge_ts: string;
    hostname: string;
    score: number;
    action: string;
  };

  blocked: Date | null;
  closed: Date | null;

  timeToBlock: Date | null;
  timeToErase: Date | null;

  rfm_segmentation?: RmfSegmentation[];
};

export type IUserContainer = {
  _id: string;
  name?: string;
  surname?: string;
  email?: string;
  role?: USER_ROLE[];
};

export type IUserBackofficeResetPassword = {
  _id: string;
  local: {
    phone: string;
    password: string | null;
  };
  blockchain: {
    address: string | null;
  };
  status: USER_STATUS;
  language: AVAILABLE_LANGUAGES_TYPE;
};

export type IUserBanned<P extends string = '_'> = IBase & {
  phone: string;
  startDate: Date | null;
  endDate?: Date | null;
  reason: string;
};
