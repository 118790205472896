import {SERVER_ERROR_CODES} from '../../constants';

export class BaseServerError extends Error {
  code: SERVER_ERROR_CODES;
  type: string;
  data: any;
  retryable: boolean;

  constructor(message: string, code: SERVER_ERROR_CODES = SERVER_ERROR_CODES.BASE_SERVER, type = 'BASE_SERVER', data?: any) {
    super(message);
    this.retryable = false;
    this.code = code;
    this.type = type;
    this.data = data;
  }
}
