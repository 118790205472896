import {IUser} from './iuser';
import {ILottery} from './ilottery';
import {IBase} from './ibase';
import {ITransaction} from './itransaction';
import {LOTTERY_NUMBER_STATUS} from 'src/constants';

export const LOTTERY_NUMBER_SIMPLE_FIELDS = (<T extends string[]>(...fields: T) => fields)('_id', 'status', 'number', 'win', 'isFreeTicket');

export type ILotteriesNumberSimple = Pick<ILotteriesNumber, typeof LOTTERY_NUMBER_SIMPLE_FIELDS[number]>;

export type ILotteriesNumber<P extends string = '_'> = IBase & {
  lottery: P extends 'lottery' ? ILottery : string;
  user: P extends 'user' ? IUser : string;
  number: number;
  win: number;
  hourly: boolean;
  status: LOTTERY_NUMBER_STATUS;
  isFreeTicket?: boolean;
  genesisFlag: string;
  transaction: P extends 'transaction' ? ITransaction : string;
};
