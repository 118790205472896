/**
 INDEX
 -------------
 1. Language
 2. Constants
 3. Enums
 4. Errors
 */

/**
 *  1. Language
 */

export const AVAILABLE_LANGUAGES = (<T extends string[]>(...fields: T) => fields)('es', 'ca', 'en', 'va', 'eu', 'gl');

export const DEFAULT_LANGUAGE = 'es';

export type AVAILABLE_LANGUAGES_TYPE = typeof AVAILABLE_LANGUAGES[number];

export type ILanguage = {
  [key in typeof AVAILABLE_LANGUAGES[number]]?: string;
};

const definition: Record<string, any> = {};
const emptyObject: ILanguage = {};
for (const lang of AVAILABLE_LANGUAGES) {
  definition[lang] = {type: String};
  emptyObject[lang] = '';
}

export const LanguageSchema = definition;

export const LanguageEmptyObject = emptyObject;

/**
 *  2. CONSTANTS
 */

export const DEFAULT_DAYS_TO_CLAIM_PRIZE = 7;

export const DEFAULT_DAYS_TO_COLLECT_PRIZE = 30;

export const MAXIMUM_METERS_TO_CONTAINER = 500;

export const SNACKBAR_TIMEOUT = 4000;

export const SNACKBAR_TIMEOUT_WITH_ACTION = 8000;

export const BANNER_TIMEOUT = 5500;

export const LIST_PAGE_SIZE = 10;

export const WIDGET_PAGE_SIZE = 3;

export const TIME_TO_RESEND_SMS = 5;

export const NOTIFICATIONS_COUNT_INTERVAL = 15000;

export const AZURE_FILE_EXTENSION = 'jpg';

export const A4DimensionsClient = {
  width: 1135,
  height: 1616,
};

export const A4DimensionsPdf = {
  width: 1134,
  height: 1604,
};

export const LAST_NEWS_PAGE_SIZE = 3;

export const SMART_BIN_NO_LIMIT = 200;

export const MAX_LOTTERY_TICKETS = 50;

export const SMART_BIN_DEMO_QR_CODE = 'DEMO_QR';

export const INFINITE_DATE = new Date('2070-01-01T00:00:00.000Z');

export const ACTION_COUNTER_KEY_PREFIX = 'ACTION_COUNT_';
export const ACTION_COUNTER_ASK_ME_LATER = 'ACTION_COUNTER_ASK_ME_LATER';
export const ACTION_COUNTER_CAN_BOTTLE_PILOT = 'ACTION_COUNTER_CAN_BOTTLE_PILOT';

/**
 *  3. ENUMS
 */

export enum MODAL_TYPE {
  Default = 'Default',
}

export enum SMSLinkAction {
  ValidateUser = 'ValidateUser',
  ForgotPassword = 'ForgotPassword',
}

export enum CIRCULAR_CHAIN_CHECKER_STATUS {
  Valid = 2,
  Fixed = 3,
  SendEmail = 4,
  ErrorToNotify = 5,
  Error = 6,
}

export enum CIRCULAR_CHAIN_CHECKER_RESULT_STATUS {
  Wrong = 1,
  Valid = 2,
  Error = 3,
}

export enum CIRCULAR_CHAIN_ACTIONS {
  Mint = 'balance-mint',
  TransferFrom = 'balance-transferFrom',
  Burn = 'balance-burn',
  GetAccountBalance = 'balance-get-account-balance',
  GetAccountTransactions = 'balance-get-account-transactions',
  BulkMint = 'balance-bulk-mint',
  GetTicket = 'lotteries-get-ticket',
  CreateLottery = 'lotteries-create',
  BuyNumbers = 'lotteries-buy-numbers',
  GetLotteryInfo = 'lotteries-get-info',
  RunLottery = 'lotteries-run',
  CreateLotteryGenesis = 'lotteries-create-genesis',
  AddTicketsGenesis = 'lotteries-add-tickets-genesis',
  SetMerkleRootAndNewLeaves = 'set-merkle-root-and-leaves',
  GetMerkleRoot = 'get-merkle-root',
}

export enum CIRCULAR_CHAIN_ACTION_STATUS {
  ERROR = -1,
  PENDING = 0,
  PROCESSING = 1,
  SUCCESS = 2,
  QUEUED = 3,
  ERROR_IN_REQUEST = 4,
}

// CONTAINERS AND OTS

export const GENERIC_OT_CODE = 'GENERIC_0_0';

export enum CONTAINER_STATUS {
  PENDING_PASTE_QR = 0,
  PASTED_QR = 1,
  INCIDENT = -1,
}

export enum OT_STATUS {
  PDT_COORDINADOR = 1,
  //OPEN = 2,
  IN_PROGRESS = 2,
  PDT_ACTIVATE = 3,
  ACTIVE = 4,
}

export enum OT_LANG {
  NONE = -1,
  ESPAÑOL = 0,
  CATALAN = 1,
  EUSKERA = 2,
  VALENCIANO = 3,
  GALLEGO = 4,
}

export enum OT_ACTIVACION_METHOD {
  NONE = -1,
  PROGRAMADO = 0,
  MANUAL = 1,
}

// PDF Formats supported for generating pdfs
export enum PDF_FORMAT {
  A4 = 'A4',
}
export enum EMAIL_TYPE {
  changeStatus = '1',
  requestBudget = '2',
}
// External calls names
export enum EXTERNAL_CALLS_IDENTIFIER {
  CAN_OR_BOTTLE = 'CAN_OR_BOTTLE',
  BARCODE_UPDATE = 'BARCODE_UPDATE',
  BARCODE_LOAD = 'BARCODE_LOAD',
  BARCODE_SEARCH = 'BARCODE_SEARCH',
  BARCODE_INSERT = 'BARCODE_INSERT',
  SMART_BIN_TOKEN = 'SMART_BIN_TOKEN',
  SMART_BIN_THROW = 'SMART_BIN_THROW',
  SMART_BIN_DONATED = 'SMART_BIN_DONATED',
  SMART_BIN_LIST_WRONG_QRS = 'SMART_BIN_LIST_WRONG_QRS',
  SMART_BIN_DETAIL_WRONG_QR = 'SMART_BIN_DETAIL_WRONG_QR',
  SMART_RING_TOKEN = 'SMART_RING_TOKEN',
  SMART_RING_TRANSACTIONS = 'SMART_RING_TRANSACTIONS',
  SEND_SMS = 'SEND_SMS',
  SEND_PUSH = 'SEND_PUSH',
  SEND_PUSH_TOPIC = 'SEND_PUSH_TOPIC',
  CRM_ACTION_POST = 'CRM_ACTION_POST',
  CRM_ACTION_PUT = 'CRM_ACTION_PUT',
  CRM_ACTION_DELETE = 'CRM_ACTION_DELETE',
  CRM_ACTION_SUSPEND = 'CRM_ACTION_SUSPEND',
  CRM_ACTION_AIRE_POST = 'CRM_ACTION_AIRE_POST',
  CRM_ACTION_AIRE_PUT = 'CRM_ACTION_AIRE_PUT',
  CRM_ACTION_AIRE_DELETE = 'CRM_ACTION_AIRE_DELETE',
  CRM_ACTION_AIRE_SUSPEND = 'CRM_ACTION_AIRE_SUSPEND',
  CRM_EMAILS_ACTION_POST = 'CRM_EMAILS_ACTION_POST',
  VM_TOKEN = 'VM_TOKEN',
  VM_CREATE_ORDER = 'VM_CREATE_ORDER',
  VM_GET_ORDER = 'VM_GET_ORDER',
  CONTAINER_CLASSIFICATION = 'CONTAINER_CLASSIFICATION',
  RE_CAPTCHA = 'RE_CAPTCHA',
  PROMOTIONAL_CODE_CRM_NOTIFICATION = 'PROMOTIONAL_CODE_CRM_NOTIFICATION',
  HUPI = 'HUPI',
}

type ExternalCallsPriorityType = {
  [k in EXTERNAL_CALLS_IDENTIFIER]: number;
};

export const EXTERNAL_CALLS_PRIORITY: ExternalCallsPriorityType = {
  [EXTERNAL_CALLS_IDENTIFIER.CAN_OR_BOTTLE]: 10,
  [EXTERNAL_CALLS_IDENTIFIER.BARCODE_UPDATE]: 20,
  [EXTERNAL_CALLS_IDENTIFIER.BARCODE_LOAD]: 20,
  [EXTERNAL_CALLS_IDENTIFIER.BARCODE_INSERT]: 20,
  [EXTERNAL_CALLS_IDENTIFIER.BARCODE_SEARCH]: 20,

  [EXTERNAL_CALLS_IDENTIFIER.SMART_BIN_TOKEN]: 10,
  [EXTERNAL_CALLS_IDENTIFIER.SMART_BIN_THROW]: 10,
  [EXTERNAL_CALLS_IDENTIFIER.SMART_BIN_DONATED]: 20,
  [EXTERNAL_CALLS_IDENTIFIER.SMART_BIN_LIST_WRONG_QRS]: 20,
  [EXTERNAL_CALLS_IDENTIFIER.SMART_BIN_DETAIL_WRONG_QR]: 20,

  [EXTERNAL_CALLS_IDENTIFIER.SMART_RING_TOKEN]: 10,
  [EXTERNAL_CALLS_IDENTIFIER.SMART_RING_TRANSACTIONS]: 20,

  [EXTERNAL_CALLS_IDENTIFIER.SEND_SMS]: 10,
  [EXTERNAL_CALLS_IDENTIFIER.SEND_PUSH]: 10,
  [EXTERNAL_CALLS_IDENTIFIER.SEND_PUSH_TOPIC]: 10,

  [EXTERNAL_CALLS_IDENTIFIER.CRM_ACTION_POST]: 30,
  [EXTERNAL_CALLS_IDENTIFIER.CRM_ACTION_PUT]: 30,
  [EXTERNAL_CALLS_IDENTIFIER.CRM_ACTION_DELETE]: 30,
  [EXTERNAL_CALLS_IDENTIFIER.CRM_ACTION_SUSPEND]: 30,
  [EXTERNAL_CALLS_IDENTIFIER.CRM_EMAILS_ACTION_POST]: 30,

  [EXTERNAL_CALLS_IDENTIFIER.CRM_ACTION_AIRE_POST]: 30,
  [EXTERNAL_CALLS_IDENTIFIER.CRM_ACTION_AIRE_PUT]: 30,
  [EXTERNAL_CALLS_IDENTIFIER.CRM_ACTION_AIRE_DELETE]: 30,
  [EXTERNAL_CALLS_IDENTIFIER.CRM_ACTION_AIRE_SUSPEND]: 30,

  [EXTERNAL_CALLS_IDENTIFIER.VM_TOKEN]: 10,
  [EXTERNAL_CALLS_IDENTIFIER.VM_GET_ORDER]: 10,
  [EXTERNAL_CALLS_IDENTIFIER.VM_CREATE_ORDER]: 10,

  [EXTERNAL_CALLS_IDENTIFIER.CONTAINER_CLASSIFICATION]: 10,
  [EXTERNAL_CALLS_IDENTIFIER.RE_CAPTCHA]: 10,

  [EXTERNAL_CALLS_IDENTIFIER.PROMOTIONAL_CODE_CRM_NOTIFICATION]: 10,
  [EXTERNAL_CALLS_IDENTIFIER.HUPI]: 10,
};

// Azure Blobs Storage
export enum AZURE_STORAGE {
  HOT = 'hot',
  COOL = 'cool',
  LOCALHOST = 'localhost',
}

// Azure Blobs Container
export enum AZURE_CONTAINER {
  Users = 'users',
  Photos = 'photos',
  Images = 'images',
  Containers = 'containers',
  Pdf = 'pdf',
  Utils = 'utils',
  ContainersPdf = 'containers-pdf',
  ContainersQr = 'containers-qr',
  ContainersPhoto = 'containers-photo',
  SmartBins = 'smart-bins',
  Videos = 'videos',
}

type AzureContainerKeys = {
  [key in AZURE_CONTAINER]: string;
};

type AzureStorageKeys = {
  [key in AZURE_CONTAINER]: AZURE_STORAGE;
};

// Extensions by container
export const CONTAINER_EXTENSION: AzureContainerKeys = {
  [AZURE_CONTAINER.Users]: 'jpg',
  [AZURE_CONTAINER.Photos]: 'jpg',
  [AZURE_CONTAINER.Images]: 'jpg',
  [AZURE_CONTAINER.Containers]: 'jpg',
  [AZURE_CONTAINER.Pdf]: 'pdf',
  [AZURE_CONTAINER.Utils]: 'json',
  [AZURE_CONTAINER.ContainersPdf]: 'pdf',
  [AZURE_CONTAINER.ContainersQr]: 'jpg',
  [AZURE_CONTAINER.ContainersPhoto]: 'jpg',
  [AZURE_CONTAINER.SmartBins]: 'jpg',
  [AZURE_CONTAINER.Videos]: 'mp4',
};

// Mimetype by container
export const CONTAINER_MIMETYPE: AzureContainerKeys = {
  [AZURE_CONTAINER.Users]: 'image/jpg',
  [AZURE_CONTAINER.Photos]: 'image/jpg',
  [AZURE_CONTAINER.Images]: 'image/jpg',
  [AZURE_CONTAINER.Containers]: 'image/jpg',
  [AZURE_CONTAINER.Pdf]: 'application/pdf',
  [AZURE_CONTAINER.Utils]: 'application/json',
  [AZURE_CONTAINER.ContainersPdf]: 'application/pdf',
  [AZURE_CONTAINER.ContainersQr]: 'image/jpg',
  [AZURE_CONTAINER.ContainersPhoto]: 'image/jpg',
  [AZURE_CONTAINER.SmartBins]: 'image/jpg',
  [AZURE_CONTAINER.Videos]: 'video/mp4',
};

// Storage by contanier
export const STORAGE_BY_CONTAINER: AzureStorageKeys = {
  [AZURE_CONTAINER.Users]: AZURE_STORAGE.COOL,
  [AZURE_CONTAINER.Photos]: AZURE_STORAGE.COOL,
  [AZURE_CONTAINER.Images]: AZURE_STORAGE.COOL,
  [AZURE_CONTAINER.Containers]: AZURE_STORAGE.COOL,
  [AZURE_CONTAINER.Pdf]: AZURE_STORAGE.COOL,
  [AZURE_CONTAINER.Utils]: AZURE_STORAGE.HOT,
  [AZURE_CONTAINER.ContainersPdf]: AZURE_STORAGE.COOL,
  [AZURE_CONTAINER.ContainersQr]: AZURE_STORAGE.COOL,
  [AZURE_CONTAINER.ContainersPhoto]: AZURE_STORAGE.COOL,
  [AZURE_CONTAINER.SmartBins]: AZURE_STORAGE.COOL,
  [AZURE_CONTAINER.Videos]: AZURE_STORAGE.COOL,
};

// User Login Type
export enum LOGIN_TYPE {
  LOCAL = 'local',
  EMAIL = 'email',
  GOOGLE = 'google',
  APPLE = 'apple',
  FACEBOOK = 'facebook',
}

// User Status
export enum USER_STATUS {
  BLOCKED = -2, // Deleted for CRM
  CLOSED = -1, // Suspended for CRM
  INVALID = 0,
  VERIFIED = 2,
  LOST_IN_FUSION = 3,
  PHONE_EXISTS = 4,
  LOST_IN_RECOVER = 5,
}

export const USER_STATUS_INACTIVE: USER_STATUS[] = [
  USER_STATUS.BLOCKED,
  USER_STATUS.CLOSED,
  USER_STATUS.LOST_IN_FUSION,
  USER_STATUS.PHONE_EXISTS,
  USER_STATUS.LOST_IN_RECOVER,
];

// User Roles
export enum USER_ROLE {
  NO = 0,
  ADMIN = 1,
  USER = 2,
  VALIDATOR = 3,
  NOTARY = 4,
  ATTENTION = 5,
  CAN_OR_BOTTLE = 6,
  MARKETING = 7,
  LABELER = 8,
  SUPER_ADMIN = 10,
  GLA = 11,
  KPMG = 12,
  MKT = 13,
  CRM = 14,
  DEMO = 15,
  REVISOR = 16,
  EXTERNAL_WEBSERVICE = 17,
  SUPERUSER_MARKETING = 18,
  SMARTRING = 19,
  MNG_USERS = 20,
  USER_COORDINATOR = 21,
  USER_SCAN = 22,
  MNG_CONTAINERS = 23,
  CONTROL_OPERACIONES = 24,
  USER_AIRE = 25,
  DEVELOPERS = 100,
}

export const USER_ROLES_ARRAY = [
  {
    label: 'NO',
    value: USER_ROLE.NO,
  },
  {
    label: 'Admin',
    value: USER_ROLE.ADMIN,
  },
  {
    label: 'User',
    value: USER_ROLE.USER,
  },
  {
    label: 'Validator',
    value: USER_ROLE.VALIDATOR,
  },
  {
    label: 'Notary',
    value: USER_ROLE.NOTARY,
  },
  {
    label: 'Attention',
    value: USER_ROLE.ATTENTION,
  },
  {
    label: 'Can or bottle',
    value: USER_ROLE.CAN_OR_BOTTLE,
  },
  {
    label: 'Marketing',
    value: USER_ROLE.MARKETING,
  },
  {
    label: 'Labeler',
    value: USER_ROLE.LABELER,
  },
  {
    label: 'Super admin',
    value: USER_ROLE.SUPER_ADMIN,
  },
  {
    label: 'GLA',
    value: USER_ROLE.GLA,
  },
  {
    label: 'KPMG',
    value: USER_ROLE.KPMG,
  },
  {
    label: 'MKT',
    value: USER_ROLE.MKT,
  },
  {
    label: 'CRM',
    value: USER_ROLE.CRM,
  },
  {
    label: 'DEMO',
    value: USER_ROLE.DEMO,
  },
  {
    label: 'Revisor',
    value: USER_ROLE.REVISOR,
  },
  {
    label: 'External webservice',
    value: USER_ROLE.EXTERNAL_WEBSERVICE,
  },
  {
    label: 'Superuser Marketing',
    value: USER_ROLE.SUPERUSER_MARKETING,
  },
  {
    label: 'Smart Ring',
    value: USER_ROLE.SMARTRING,
  },
  {
    label: 'Mng. Users',
    value: USER_ROLE.MNG_USERS,
  },
  {
    label: 'Coordinator',
    value: USER_ROLE.USER_COORDINATOR,
  },
  {
    label: 'User Scan',
    value: USER_ROLE.USER_SCAN,
  },
  {
    label: 'Mng. containers',
    value: USER_ROLE.MNG_CONTAINERS,
  },
  {
    label: 'Control Operaciones',
    value: USER_ROLE.CONTROL_OPERACIONES,
  },
];

// User Categories
export enum USER_CATEGORY {
  HOGAR = 'SOLO_HOGAR',
  FUERA_HOGAR = 'SOLO_FUERA_HOGAR',
  HOGAR_Y_FUERA_HOGAR = 'HOGAR_Y_FUERA_HOGAR',
  ESPONTANEOS = 'ESPONTANEOS',
  CONTAINER_APP = 'CONTAINER_APP',
}

// Smart Ring User Categories
export enum SMART_RING_CATEGORY {
  NU = 'NU', // Nuevos usuarios sin cuenta RECICLOS en la fecha de lanzamiento del piloto
  NU_SR = 'NU_SR', // Nuevos usuarios sin cuenta RECICLOS en la fecha de lanzamiento del piloto que han escaneado un QR de Smart Ring
  NU_C = 'NU_C', // Nuevos usuarios sin cuenta RECICLOS en la fecha de lanzamiento del piloto que han escaneado un QR de Contenedor
  NU_I = 'NU_I', // Nuevos usuarios sin cuenta RECICLOS en la fecha de lanzamiento del piloto ya registrados que no hayan realizado ninguna activitad (referente a generación básica de RECICLOS) más allá del registro
  RA = 'RA', // Usuarios registrados anteriormente en RECICLOS que sigan activos en el proyecto en la fecha de lanzamiento del piloto (last login en los ultimos 30 dias)
  RA_SR = 'RA_SR', // Usuarios registrados anteriormente en RECICLOS que sigan activos en el proyecto en la fecha de lanzamiento del piloto (last login en los ultimos 30 dias) y han escaneado un QR de Smart Ring
  RA_C = 'RA_C', // Usuarios registrados anteriormente en RECICLOS que sigan activos en el proyecto en la fecha de lanzamiento del piloto (last login en los ultimos 30 dias) y NO han escaneado un QR de Smart Ring
  RN = 'RN', // Usuarios registrados anteriormente en RECICLOS que NO sigan activos en el proyecto en la fecha de lanzamiento del piloto (last login mayor a los ultimos 30 dias)
  RN_SR = 'RN_SR', // Usuarios registrados anteriormente en RECICLOS que NO sigan activos en el proyecto en la fecha de lanzamiento del piloto (last login anterior a los ultimos 30 dias) y hayan escaneado un QR de Smart Ring
  RN_I = 'RN_I', // Usuarios registrados anteriormente en RECICLOS que NO sigan activos en el proyecto en la fecha de lanzamiento del piloto (last login anterior a los ultimos 30 dias) y NO hayan escaneado un QR de Smart Ring
  RN_CR = 'RN_CR', // Usuarios registrados anteriormente en RECICLOS que NO sigan activos en el proyecto en la fecha de lanzamiento del piloto (last login anterior a los ultimos 30 dias) y hayan vuelto a ser activos en RECICLOS hogar usual
}

// Backoffice user company
export enum Company {
  Ecoembes = 'ecoembes',
  BlueRoom = 'blueroom',
  VivaMarketing = 'vivamarketing',
}

export enum BARCODE_CHECK {
  NotFound,
  Invalid,
  Valid,
}

export enum REPORT_TYPE {
  GlaMonthly = 'GlaMonthly',
}

// Lottery Status
export enum LOTTERY_CLIENT_STATUS {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
}

// Lottery Client Type
export enum LOTTERY_COMMUNITY_TYPE {
  Home = 'Home',
  SmartBin = 'SmartBin',
  All = 'All',
}

// Lottery Status Internal
export enum LOTTERY_STATUS {
  CREATING = 0,
  INACTIVE = 1,
  ACTIVATING = 2,
  ACTIVE = 3,
  RUNNING = 4,
  FINISHED = 5,
  ERROR_OLD = 666,
  ERROR_CREATING = 660,
  ERROR_ACTIVATING = 662,
  ERROR_RUNNING = 664,
  ERROR_FOREVER = 6666,
}

export enum INCENTIVE_CATEGORY {
  ACCESORIO_ELECTRONICO = 'ACCESORIO_ELECTRONICO',
  EXPERIENCIA_ACTIVIDAD = 'EXPERIENCIA_ACTIVIDAD',
  EXPERIENCIA_HOTEL = 'EXPERIENCIA_HOTEL',
  TEXTIL = 'TEXTIL',
  COSMETICA = 'COSMETICA',
  JARDINERIA = 'JARDINERIA',
  ACCESORIOS = 'ACCESORIOS',
  BICICLETA = 'BICICLETA',
  PATINETE = 'PATINETE',
  MOVILIDAD = 'MOVILIDAD',
  CUBO_DE_RECICLAJE = 'CUBO_DE_RECICLAJE',
  ALIMETACION_ECOLOGICA = 'ALIMETACION_ECOLOGICA',
  VIDA_SALUDABLE = 'VIDA_SALUDABLE',
  ACCESORIOS_HOGAR = 'ACCESORIOS_HOGAR',
  COMPLEMENTOS = 'COMPLEMENTOS',
  EXPERIENCIA_GASTRONOMIA = 'EXPERIENCIA_GASTRONOMIA',
}

export const LOTTERY_STATUS_ERRORS = [LOTTERY_STATUS.ERROR_CREATING, LOTTERY_STATUS.ERROR_ACTIVATING, LOTTERY_STATUS.ERROR_RUNNING, LOTTERY_STATUS.ERROR_FOREVER];

export enum CAMPAIGN_CODE {
  USERS_REACTIVATION = 'USERS_REACTIVATION',
  OLDER_USERS_REACTIVATION = 'OLDER_USERS_REACTIVATION',
  COMPENSATE_POWER_USERS = 'COMPENSATE_POWER_USERS',
  REACTIVATE_NO_REGISTERED_USERS = 'REACTIVATE_NO_REGISTERED_USERS',
  WELCOMED_USERS_REACTIVATION = 'WELCOMED_USERS_REACTIVATION',
}

export enum CAMPAIGN_ACTION {
  SEND_NOTIFICATION = 'SEND_NOTIFICATION',
  SEND_NOTIFICATION_PUSH = 'SEND_NOTIFICATION_PUSH',
  SEND_SMS = 'SEND_SMS',
  BOX_POST = 'BOX_POST',
  SEND_SMS_DISABLED = 'SEND_SMS_DISABLED',
  FREE_LOTTERY_TICKET = 'FREE_LOTTERY_TICKET',
  FREE_LOTTERY_TICKET_NOT_FOUND = 'FREE_LOTTERY_TICKET_NOT_FOUND',
  REACTIVATION = 'REACTIVATION',
  USER_VALIDATED = 'USER_VALIDATED',
}

export enum POST_UUID {
  COMPENSATE_ACTIVE_USERS_1 = 'COMPENSATE_ACTIVE_USERS_1',
  COMPENSATE_ACTIVE_USERS_2 = 'COMPENSATE_ACTIVE_USERS_2',
  USER_ACTIVITY_GROUP_1_NEVER_SCANNED = 'USER_ACTIVITY_GROUP_1_NEVER_SCANNED',
  USER_ACTIVITY_GROUP_1_NEVER_GO_CONTAINER = 'USER_ACTIVITY_GROUP_1_NEVER_GO_CONTAINER',
  USER_ACTIVITY_GROUP_1_NEVER_GO_DOOR_TO_DOOR_CONTAINER = 'USER_ACTIVITY_GROUP_1_NEVER_GO_DOOR_TO_DOOR_CONTAINER',
  USER_ACTIVITY_GROUP_1_NEVER_SPEND = 'USER_ACTIVITY_FIRST_BUCKET_THROW',
  USER_ACTIVITY_GROUP_1_NEVER_INVITE = 'USER_ACTIVITY_GROUP_1_NEVER_INVITE',
  USER_ACTIVITY_GROUP_2_WITH_RECICLOS = 'USER_ACTIVITY_GROUP_2_WITH_RECICLOS',
  USER_ACTIVITY_GROUP_2_NO_RECICLOS = 'USER_ACTIVITY_GROUP_2_NO_RECICLOS',
  USER_ACTIVITY_GROUP_3_REASON_1 = 'USER_ACTIVITY_GROUP_3_REASON_1',
  USER_ACTIVITY_GROUP_3_REASON_2 = 'USER_ACTIVITY_GROUP_3_REASON_2',
  USER_ACTIVITY_GROUP_3_REASON_3 = 'USER_ACTIVITY_GROUP_3_REASON_3',
  USER_ACTIVITY_GROUP_3_REASON_4 = 'USER_ACTIVITY_GROUP_3_REASON_4',
  USER_ACTIVITY_GROUP_4_WEEK_1 = 'USER_ACTIVITY_GROUP_4_WEEK_1',
  USER_ACTIVITY_GROUP_4_WEEK_2 = 'USER_ACTIVITY_GROUP_4_WEEK_2',
  USER_ACTIVITY_GROUP_4_WEEK_3 = 'USER_ACTIVITY_GROUP_4_WEEK_3',
  USER_ACTIVITY_GROUP_4_WEEK_4 = 'USER_ACTIVITY_GROUP_4_WEEK_4',
  USER_ACTIVITY_GROUP_5 = 'USER_ACTIVITY_GROUP_5',
  USER_ACTIVITY_WEEKLY_LIMIT_REACHED_1 = 'USER_ACTIVITY_WEEKLY_LIMIT_REACHED_1',
  USER_ACTIVITY_WEEKLY_LIMIT_REACHED_2 = 'USER_ACTIVITY_WEEKLY_LIMIT_REACHED_2',
  USER_ACTIVITY_WEEKLY_LIMIT_REACHED_3 = 'USER_ACTIVITY_WEEKLY_LIMIT_REACHED_3',
  USER_ACTIVITY_WEEKLY_LIMIT_REACHED_4 = 'USER_ACTIVITY_WEEKLY_LIMIT_REACHED_4',
  USER_ACTIVITY_STREAMLINE_SPENDING_CROWD_1 = 'USER_ACTIVITY_STREAMLINE_SPENDING_CROWD_1',
  USER_ACTIVITY_STREAMLINE_SPENDING_CROWD_2 = 'USER_ACTIVITY_STREAMLINE_SPENDING_CROWD_2',
  USER_ACTIVITY_STREAMLINE_SPENDING_CROWD_3 = 'USER_ACTIVITY_STREAMLINE_SPENDING_CROWD_3',
  USER_ACTIVITY_STREAMLINE_SPENDING_WEEKLY_LOTTERY_1 = 'USER_ACTIVITY_STREAMLINE_SPENDING_WEEKLY_LOTTERY_1',
  USER_ACTIVITY_STREAMLINE_SPENDING_WEEKLY_LOTTERY_2 = 'USER_ACTIVITY_STREAMLINE_SPENDING_WEEKLY_LOTTERY_2',
  USER_ACTIVITY_STREAMLINE_SPENDING_MONTHLY_LOTTERY_1 = 'USER_ACTIVITY_STREAMLINE_SPENDING_MONTHLY_LOTTERY_1',
  USER_ACTIVITY_STREAMLINE_SPENDING_MONTHLY_LOTTERY_2 = 'USER_ACTIVITY_STREAMLINE_SPENDING_MONTHLY_LOTTERY_2',
  USER_ACTIVITY_MGM_VALIDATED = 'USER_ACTIVITY_MGM_VALIDATED',
  USER_ACTIVITY_MGM_INVITATION_RACE = 'USER_ACTIVITY_MGM_INVITATION_RACE',

  CUSTOMER_JOURNEY_OLD_USERS_DAY_0 = 'CUSTOMER_JOURNEY_OLD_USERS_DAY_0',
  CUSTOMER_JOURNEY_OLD_USERS_DAY_0_STORY_1 = 'CUSTOMER_JOURNEY_OLD_USERS_DAY_0_STORY_1',
  CUSTOMER_JOURNEY_OLD_USERS_DAY_0_STORY_2 = 'CUSTOMER_JOURNEY_OLD_USERS_DAY_0_STORY_2',
  CUSTOMER_JOURNEY_OLD_USERS_DAY_0_STORY_3 = 'CUSTOMER_JOURNEY_OLD_USERS_DAY_0_STORY_3',
  CUSTOMER_JOURNEY_OLD_USERS_DAY_0_STORY_4 = 'CUSTOMER_JOURNEY_OLD_USERS_DAY_0_STORY_4',
  CUSTOMER_JOURNEY_OLD_USERS_DAY_3 = 'CUSTOMER_JOURNEY_OLD_USERS_DAY_3',
  CUSTOMER_JOURNEY_OLD_USERS_DAY_4 = 'CUSTOMER_JOURNEY_OLD_USERS_DAY_4',
  CUSTOMER_JOURNEY_OLD_USERS_DAY_5 = 'CUSTOMER_JOURNEY_OLD_USERS_DAY_5',
  CUSTOMER_JOURNEY_OLD_USERS_DAY_6 = 'CUSTOMER_JOURNEY_OLD_USERS_DAY_6',
  CUSTOMER_JOURNEY_OLD_USERS_DAY_7 = 'CUSTOMER_JOURNEY_OLD_USERS_DAY_7',
  CUSTOMER_JOURNEY_OLD_USERS_DAY_10 = 'CUSTOMER_JOURNEY_OLD_USERS_DAY_10',
  CUSTOMER_JOURNEY_OLD_USERS_DAY_12 = 'CUSTOMER_JOURNEY_OLD_USERS_DAY_12',
  CUSTOMER_JOURNEY_OLD_USERS_DAY_14 = 'CUSTOMER_JOURNEY_OLD_USERS_DAY_14',

  CUSTOMER_JOURNEY_DAY_0 = 'CUSTOMER_JOURNEY_DAY_0',
  CUSTOMER_JOURNEY_DAY_1 = 'CUSTOMER_JOURNEY_DAY_1',
  CUSTOMER_JOURNEY_DAY_2 = 'CUSTOMER_JOURNEY_DAY_2',
  CUSTOMER_JOURNEY_DAY_3 = 'CUSTOMER_JOURNEY_DAY_3',
  CUSTOMER_JOURNEY_DAY_6 = 'CUSTOMER_JOURNEY_DAY_6',
  CUSTOMER_JOURNEY_DAY_8 = 'CUSTOMER_JOURNEY_DAY_8',
  CUSTOMER_JOURNEY_DAY_9 = 'CUSTOMER_JOURNEY_DAY_9',
  CUSTOMER_JOURNEY_DAY_10 = 'CUSTOMER_JOURNEY_DAY_10',
  CUSTOMER_JOURNEY_DAY_11 = 'CUSTOMER_JOURNEY_DAY_11',
  CUSTOMER_JOURNEY_DAY_13 = 'CUSTOMER_JOURNEY_DAY_13',

  CUSTOMER_JOURNEY_USER_ACTIVITY_DAY_0 = 'CUSTOMER_JOURNEY_USER_ACTIVITY_DAY_0',
  SMART_RING_RELEASE = 'SMART_RING_RELEASE',
}

export enum PROMOTIONS_TYPE {
  GIVE_EXTRA_RECICLOS_ON_THROW = 'GIVE_EXTRA_RECICLOS_ON_THROW',
  GIVE_EXTRA_RECICLOS_ON_DONATE = 'GIVE_EXTRA_RECICLOS_ON_DONATE',
}

export enum PROMOTION_TEMPLATE_TYPE {
  default = 'default',
  community = 'community',
  special = 'special',
}

export enum PURCHASE_TYPE {
  CROWD = 'Proyectos',
  LOTTERY = 'Sorteos',
  DIRECT_INCENTIVE = 'Canje Directo',
  MICRO_INCENTIVE = 'Premio Directo',
}

export enum GENERATION_TYPE {
  WELCOME = 'WELCOME',
  MGM = 'MGM',
  SMART_BIN = 'SMART_BIN',
  SMART_RING = 'SMART_RING',
  PHOTO = 'PHOTO',
}

export enum LOTTERY_TYPE {
  JACKPOT = 1,
  PRIZE = 2,
}

// Lottery Number
export enum LOTTERY_NUMBER_STATUS {
  PENDING = 0,
  SUCCESS = 1,
  ERROR = 2,
  NOT_IN_CIRCULAR_CHAIN = 3,
}

// Feed Enum
export enum FEED_HOME {
  BOX = 'BOX',
  STORY = 'STORY',
  CARD = 'CARD',
}

// Post render types
export enum POST_STORY_RENDER_TYPE {
  GENERIC = 'GENERIC',
  IMAGE = 'IMAGE',
}
export enum POST_CARD_RENDER_TYPE {
  GENERIC = 'GENERIC',
  LOTTERY_WINNER = 'LOTTERY_WINNER',
}

// Post Priority
export enum POST_PRIORITY {
  IMPORTANT = 1,
  INCENTIVE = 2,
  USER_ACTIVITY = 3,
  CUSTOMER_JOURNEY = 4,
  OTHER = 5,
}

export type RENDER_PARAMS_TYPE = {
  winnerNumbers: number[];
};

export type USER_PARAMS_TYPE = {
  nReciclos?: number;
  nDays?: string;
  isDoorToDoor?: boolean;
  prize?: any;
  nCansOrBottles?: number;
  limit?: number;
  objectId?: string;
};

// Text generation type
export enum TEXT_GENERATION_TYPE {
  CROWD_INITIATED = 'CROWD_INITIATED',
  CROWD_FINISHED = 'CROWD_FINISHED',
  CROWD_60_PER_CENT_ACCOMPLISHED = 'CROWD_60_PER_CENT_ACCOMPLISHED',
  LOTTERY_INITIATED = 'LOTTERY_INITIATED',
  LOTTERY_WEEKLY_ALERT = 'LOTTERY_WEEKLY_ALERT',
  LOTTERY_MONTHLY_ALERT = 'LOTTERY_MONTHLY_ALERT',
  LOTTERY_FINISHED = 'LOTTERY_FINISHED',
  DIRECT_PRIZE_INITIATED = 'DIRECT_PRIZE_INITIATED',
  MICRO_INCENTIVE_INITIATED = 'MICRO_INCENTIVE_INITIATED',
  MICRO_INCENTIVE_DIRECT_INITIATED = 'MICRO_INCENTIVE_DIRECT_INITIATED',
  MICRO_INCENTIVE_GENERATE_PLAY = 'MICRO_INCENTIVE_GENERATE_PLAY',
  MICRO_INCENTIVE_SHORT_STOCK = 'MICRO_INCENTIVE_SHORT_STOCK',
  DEDUCT_FROM_UUID = 'DEDUCT_FROM_UUID',
  DIRECT_INCENTIVE_INITIATED = 'DIRECT_INCENTIVE_INITIATED',
  DIRECT_INCENTIVE_WEEKLY_ALERT = 'DIRECT_INCENTIVE_WEEKLY_ALERT',
  DIRECT_INCENTIVE_AFTER_BUY = 'DIRECT_INCENTIVE_AFTER_BUY',
  CONTENT_FROM_POST = 'CONTENT_FROM_POST',
}

// Post Action
export enum POST_ACTION {
  LINK_TO_OBJECT = 'LINK_TO_OBJECT',
  LINK_TO_SCREEN = 'LINK_TO_SCREEN',
}

// Post Generate Filter Type
export enum POST_GENERATION_FILTER {
  COMMUNITIES = 'COMMUNITIES',
  DAY_SINCE_REGISTER = 'DAY_SINCE_REGISTER',
  CLOSED_USERS = 'CLOSED_USERS',
  USERS_WITH_BLACKLIST = 'USERS_WITH_BLACKLIST',
  USERS_WITH_WHITELIST = 'USERS_WITH_WHITELIST',
}

// Transactions
export enum TRANSACTION_STATUS {
  CREATED = 0,
  PENDING = 1,
  SUCCESS = 2,
  ERROR = 3,
  TIMEOUT = 4,
  CHECKER_ERROR = 5,
  DUPLICATED_ERROR = 6,
  MGM_WRONG_TRANSACTION = 62,
}

// Transactions
export enum TRANSACTION_TYPE {
  WELCOME = 'welcome',
  MEMBER_GET_MEMBER = 'MGM',
  BUY_NUMBERS = 'jackpot',
  ASSIGN_RECICLOS_FREE_TICKET = 'ASSIGN_RECICLOS_FREE_TICKET',
  FREE_NUMBERS = 'free-jackpot',
  DONATION_CROWD = 'crowd',
  RETREE_PLANT = 'RETREE_PLANT',
  RETREE_FERTILIZE = 'RETREE_FERTILIZE',
  RETREE_WATER = 'RETREE_WATER',
  RETREE_TRANSPLANT = 'RETREE_TRANSPLANT',
  FREE_RETREE_RECICLOS = 'free-retree-reciclos',
  BUCKET_THROW = 'bucket-throw',
  SMART_BIN_THROW = 'smart-bin',
  RECICLOS_GENESIS = 'reciclos-genesis',
  LOTTERY_TO_USER = 'LOTTERY_TO_USER',
  TEMPORAL = 'TEMPORAL',
  MICRO_INCENTIVE_PLAY = 'micro-incentive-play',
  POLL_REWARD = 'poll-reward',
  BUY_DIRECT_INCENTIVE = 'direct-incentive',
  CROWD_TO_ARCHIVE = 'CROWD_TO_ARCHIVE',
  LOTTERY_TO_ARCHIVE = 'LOTTERY_TO_ARCHIVE',
  LOTTERY_WINNER_TO_ARCHIVE = 'LOTTERY_WINNER_TO_ARCHIVE',
  MICRO_INCENTIVE_TO_ARCHIVE = 'MICRO_INCENTIVE_TO_ARCHIVE',
  DIRECT_INCENTIVE_TO_ARCHIVE = 'DIRECT_INCENTIVE_TO_ARCHIVE',
  SMART_RING_THROW = 'smart-ring',
  SMART_RING_DONATION_MINT = 'smart-ring-donation-mint',
  SMART_BIN_DONATION_MINT = 'smart-bin-donation-mint',
  FUERA_HOGAR_DONATION_CROWD = 'fuera-hogar-donation-crowd',
  HOGAR_DONATION_CROWD = 'hogar-donation-crowd',
  RECICLOS_GIFT = 'reciclos-gift',
  RECICLOS_RECTIFICATION = 'reciclos-rectification',
  RECICLOS_EXPIRATION = 'reciclos-expiration',
  RECICLOS_EXPIRATION_ARCHIVE = 'reciclos-expiration-archive',
  COMERCIAL_ACTION = 'reciclos-comercial-action',
  ROLLBACK_TO_ARCHIVE = 'ROLLBACK_TO_ARCHIVE',
  COMMUNITY_DONATION = 'community-donation',
  PROMOTIONAL_CODE_EXCHANGE = 'promotional-code-exchange',
  // Old transactions, keep for transactions list only
  MINT = 'mint',
  MINT_PHOTO = 'mint-photo',
  PHOTO = 'photo',
  CIRCULAR_CHAIN_PHOTO = 'photo-cc',
  BIN_TO_CROWD = 'bin-to-crowds',
  MINT_BIN = 'mint-bin',
  JACKPOT_INITIAL = 'mint-jackpot',
  JACKPOT_WIN = 'win-jackpot',
  SCAN = 'SCAN',
  TRANSFER = 'TRANSFER',
  VALIDATION = 'VALIDATION',
  DONATION_PROMOTION_CROWD = 'promotion-crowd',
  DONATION_ONG = 'donation',
  LOTTERY_SECOND_INITIAL = 'mint-lottery-second',
  LOTTERY_SECOND_WIN = 'lottery-second-win',
  REFOUND_TICKET = 'refound-ticket',
  WIN_RECICLONIC = 'win-reciclonic',
  MEMBER_GET_MEMBER_RECYCLED = 'MGM_RECYCLED',
  PROMOTIONAL_CODE_EXCHANGE_LIST = 'promotional-code-exchange-list',
}

export enum TRANSACTION_SUBTYPE {
  MARATON_VIGO = 'maraton-vigo',
  DIA_DE_LA_TIERRA = 'dia-de-la-tierra',
  DIA_MUNDIAL_DEL_RECICLAJE = 'dia-mundial-del-reciclaje',
  DONACIONES_AUTONOMICAS_ESPECIALES = 'donaciones-autonomicas-expeciales',
  MGM_ON_REGISTER = 'mgm-on-register',
  MGM_ON_EARN_REFERRER = 'mgm-on-earn-referrer',
  MGM_ON_EARN_REFERRED = 'mgm-on-earn-referred',
  DIA_DEL_MEDIOAMBIENTE = 'dia-del-medioambiente',
  DIA_DE_LAS_PLAYAS = 'dia-mundial-de-las-playas',
  DIA_DE_LOS_MONTES = 'dia-mundial-de-los-montes',
  GREEN_WEEK = 'green-week',
}

export enum TREE_STAGE {
  SEED = 'SEED',
  PLANTING = 'PLANTING',
  PLANTED = 'PLANTED',
  FERTILIZING = 'FERTILIZING',
  FERTILIZED = 'FERTILIZED',
  WATERING = 'WATERING',
  WATERED = 'WATERED',
  TRANSPLANTING = 'TRANSPLANTING',
  TRANSPLANTED = 'TRANSPLANTED',
}

export type RetreeRuleType = {
  currentStage: TREE_STAGE;
  intermediateStage: TREE_STAGE;
  nextStage: TREE_STAGE;
  txValue: number;
  txType: TRANSACTION_TYPE;
};

export const RetreeRules: RetreeRuleType[] = [
  {
    currentStage: TREE_STAGE.SEED,
    intermediateStage: TREE_STAGE.PLANTING,
    nextStage: TREE_STAGE.PLANTED,
    txValue: 5,
    txType: TRANSACTION_TYPE.RETREE_PLANT,
  },
  {
    currentStage: TREE_STAGE.PLANTED,
    intermediateStage: TREE_STAGE.FERTILIZING,
    nextStage: TREE_STAGE.FERTILIZED,
    txValue: 5,
    txType: TRANSACTION_TYPE.RETREE_FERTILIZE,
  },
  {
    currentStage: TREE_STAGE.FERTILIZED,
    intermediateStage: TREE_STAGE.WATERING,
    nextStage: TREE_STAGE.WATERED,
    txValue: 5,
    txType: TRANSACTION_TYPE.RETREE_WATER,
  },
  {
    currentStage: TREE_STAGE.WATERED,
    intermediateStage: TREE_STAGE.TRANSPLANTING,
    nextStage: TREE_STAGE.TRANSPLANTED,
    txValue: 10,
    txType: TRANSACTION_TYPE.RETREE_TRANSPLANT,
  },
];

export enum RECICLOS_ACTION_TYPE {
  NEW_USER = 'NewUser',
  TREE = 'Tree',
  COMMUNITY = 'Community',
  LOTTERY = 'Lottery',
  CROWD = 'Crowd',
  SMART_BIN = 'SmartBin',
  OLD_ONG = 'Ong',
  RecyclePhoto = 'RecyclePhoto',
  MicroIncentive = 'MicroIncentive',
  DirectIncentive = 'DirectIncentive',
  Archive = 'Archive',
  SMART_RING = 'SmartRing',
  // PROMOTIONAL_CODE = 'PromotionalCode',
}

type TransactionFromToModelsType = {
  [key in TRANSACTION_TYPE]: {
    from: RECICLOS_ACTION_TYPE | null;
    to: RECICLOS_ACTION_TYPE | null;
  };
};

export const RECICLOS_SPEND_ACTION_TYPES = [
  RECICLOS_ACTION_TYPE.CROWD,
  RECICLOS_ACTION_TYPE.LOTTERY,
  RECICLOS_ACTION_TYPE.MicroIncentive,
  RECICLOS_ACTION_TYPE.DirectIncentive,
];

export const TransactionFromToModels: TransactionFromToModelsType = {
  /**
   * OLD transactions types not used now
   */
  [TRANSACTION_TYPE.WIN_RECICLONIC]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.BIN_TO_CROWD]: {
    from: RECICLOS_ACTION_TYPE.COMMUNITY,
    to: RECICLOS_ACTION_TYPE.CROWD,
  },
  [TRANSACTION_TYPE.MINT_BIN]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.COMMUNITY,
  },
  [TRANSACTION_TYPE.LOTTERY_SECOND_INITIAL]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.LOTTERY,
  },
  [TRANSACTION_TYPE.LOTTERY_SECOND_WIN]: {
    from: RECICLOS_ACTION_TYPE.LOTTERY,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.DONATION_ONG]: {
    from: RECICLOS_ACTION_TYPE.NEW_USER,
    to: RECICLOS_ACTION_TYPE.OLD_ONG,
  },
  [TRANSACTION_TYPE.JACKPOT_INITIAL]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.LOTTERY,
  },
  [TRANSACTION_TYPE.JACKPOT_WIN]: {
    from: RECICLOS_ACTION_TYPE.LOTTERY,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.TRANSFER]: {
    from: RECICLOS_ACTION_TYPE.NEW_USER,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.SCAN]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.VALIDATION]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.MINT_PHOTO]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.RecyclePhoto,
  },
  [TRANSACTION_TYPE.CIRCULAR_CHAIN_PHOTO]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.PHOTO]: {
    from: RECICLOS_ACTION_TYPE.RecyclePhoto,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },

  /**
   * Current used types
   */
  [TRANSACTION_TYPE.WELCOME]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.MEMBER_GET_MEMBER]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.SMART_BIN_THROW]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.BUCKET_THROW]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.RECICLOS_GENESIS]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.POLL_REWARD]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.MINT]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.BUY_NUMBERS]: {
    from: RECICLOS_ACTION_TYPE.NEW_USER,
    to: RECICLOS_ACTION_TYPE.LOTTERY,
  },
  [TRANSACTION_TYPE.FREE_NUMBERS]: {
    from: RECICLOS_ACTION_TYPE.NEW_USER,
    to: RECICLOS_ACTION_TYPE.LOTTERY,
  },
  [TRANSACTION_TYPE.REFOUND_TICKET]: {
    from: RECICLOS_ACTION_TYPE.LOTTERY,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.DONATION_CROWD]: {
    from: RECICLOS_ACTION_TYPE.NEW_USER,
    to: RECICLOS_ACTION_TYPE.CROWD,
  },
  [TRANSACTION_TYPE.DONATION_PROMOTION_CROWD]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.CROWD,
  },
  [TRANSACTION_TYPE.SMART_BIN_DONATION_MINT]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.COMMUNITY,
  },
  [TRANSACTION_TYPE.MICRO_INCENTIVE_PLAY]: {
    from: RECICLOS_ACTION_TYPE.NEW_USER,
    to: RECICLOS_ACTION_TYPE.MicroIncentive,
  },
  [TRANSACTION_TYPE.BUY_DIRECT_INCENTIVE]: {
    from: RECICLOS_ACTION_TYPE.NEW_USER,
    to: RECICLOS_ACTION_TYPE.DirectIncentive,
  },
  [TRANSACTION_TYPE.TEMPORAL]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.LOTTERY_TO_USER]: {
    from: RECICLOS_ACTION_TYPE.LOTTERY,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.ROLLBACK_TO_ARCHIVE]: {
    from: RECICLOS_ACTION_TYPE.NEW_USER,
    to: RECICLOS_ACTION_TYPE.Archive,
  },
  [TRANSACTION_TYPE.CROWD_TO_ARCHIVE]: {
    from: RECICLOS_ACTION_TYPE.CROWD,
    to: null,
  },
  [TRANSACTION_TYPE.LOTTERY_TO_ARCHIVE]: {
    from: RECICLOS_ACTION_TYPE.LOTTERY,
    to: null,
  },
  [TRANSACTION_TYPE.LOTTERY_WINNER_TO_ARCHIVE]: {
    from: RECICLOS_ACTION_TYPE.LOTTERY,
    to: null,
  },
  [TRANSACTION_TYPE.MICRO_INCENTIVE_TO_ARCHIVE]: {
    from: RECICLOS_ACTION_TYPE.MicroIncentive,
    to: null,
  },
  [TRANSACTION_TYPE.DIRECT_INCENTIVE_TO_ARCHIVE]: {
    from: RECICLOS_ACTION_TYPE.DirectIncentive,
    to: null,
  },
  [TRANSACTION_TYPE.ASSIGN_RECICLOS_FREE_TICKET]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.SMART_RING_THROW]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.SMART_RING_DONATION_MINT]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.COMMUNITY,
  },
  [TRANSACTION_TYPE.FUERA_HOGAR_DONATION_CROWD]: {
    from: RECICLOS_ACTION_TYPE.COMMUNITY,
    to: RECICLOS_ACTION_TYPE.CROWD,
  },
  [TRANSACTION_TYPE.HOGAR_DONATION_CROWD]: {
    from: RECICLOS_ACTION_TYPE.COMMUNITY,
    to: RECICLOS_ACTION_TYPE.CROWD,
  },
  [TRANSACTION_TYPE.RECICLOS_GIFT]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.RECICLOS_RECTIFICATION]: {
    from: RECICLOS_ACTION_TYPE.NEW_USER,
    to: RECICLOS_ACTION_TYPE.Archive,
  },
  [TRANSACTION_TYPE.RECICLOS_EXPIRATION]: {
    from: RECICLOS_ACTION_TYPE.NEW_USER,
    to: RECICLOS_ACTION_TYPE.CROWD,
  },
  [TRANSACTION_TYPE.RECICLOS_EXPIRATION_ARCHIVE]: {
    from: RECICLOS_ACTION_TYPE.NEW_USER,
    to: null,
  },
  [TRANSACTION_TYPE.COMERCIAL_ACTION]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.MEMBER_GET_MEMBER_RECYCLED]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.RETREE_PLANT]: {
    from: RECICLOS_ACTION_TYPE.NEW_USER,
    to: RECICLOS_ACTION_TYPE.TREE,
  },
  [TRANSACTION_TYPE.RETREE_FERTILIZE]: {
    from: RECICLOS_ACTION_TYPE.NEW_USER,
    to: RECICLOS_ACTION_TYPE.TREE,
  },
  [TRANSACTION_TYPE.RETREE_WATER]: {
    from: RECICLOS_ACTION_TYPE.NEW_USER,
    to: RECICLOS_ACTION_TYPE.TREE,
  },
  [TRANSACTION_TYPE.RETREE_TRANSPLANT]: {
    from: RECICLOS_ACTION_TYPE.NEW_USER,
    to: RECICLOS_ACTION_TYPE.TREE,
  },
  [TRANSACTION_TYPE.FREE_RETREE_RECICLOS]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.COMMUNITY_DONATION]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.CROWD,
  },
  [TRANSACTION_TYPE.PROMOTIONAL_CODE_EXCHANGE]: {
    // from: RECICLOS_ACTION_TYPE.PROMOTIONAL_CODE,
    from: null,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
  [TRANSACTION_TYPE.PROMOTIONAL_CODE_EXCHANGE_LIST]: {
    from: null,
    to: RECICLOS_ACTION_TYPE.NEW_USER,
  },
};

// Campaigns status
export enum CROWD_CAMPAIGN_STATUS {
  INACTIVE = 0,
  ACTIVE = 1,
  COMING_SOON = 2,
  FINISHED = 3,
}

export enum CROWD_CATEGORY {
  APOYO_A_PERSONAS_VULNERABLES = 'APOYO_A_PERSONAS_VULNERABLES',
  APOYO_INFANTIL = 'APOYO_INFANTIL',
  APOYO_TRANSPORTE_SOSTENIBLE = 'APOYO_TRANSPORTE_SOSTENIBLE',
  MEJORA_REHAB_ZONAS = 'MEJORA_REHAB_ZONAS',
  PROTECCION_ANIMAL = 'PROTECCION_ANIMAL',
  OTROS = 'OTROS',
}

export enum ONG_STATUS {
  INACTIVE = 0,
  ACTIVE = 1,
  COMING_SOON = 2,
  FINISHED = 3,
}

export enum PRIZE_TYPE {
  SEND_TO_USER = 'SEND_TO_USER',
  COLLECTION_POINT = 'COLLECTION_POINT',
  COLLECTION_POINT_THIRD_PARTY = 'COLLECTION_POINT_THIRD_PARTY',
  VALEN_BUS = 'VALEN_BUS',
  VALEN_BICI = 'VALEN_BICI',
  ZOO_MADRID = 'ZOO_MADRID',
  FAUNIA = 'FAUNIA',
  OCEANOGRAFIC = 'OCEANOGRAFIC',
}

export enum CAMPAIGN_TRACKING_STATUS {
  STARTED = 'STARTED',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum RECYCLE_PHOTO_STATUS {
  // Primer estado, se esta procesando la foto para saber si es lata / botella y comprobar si es duplicada
  PROCESSING = 0,

  // Incidencia
  INCIDENCE = 1,

  // Se ha detectado una foto duplicada a esta
  // INCIDENCE_DUPLICATED = 100,
  // Se ha detectado que no sabemos si es lata o botella
  // INCIDENCE_CAN_OR_BOTTLE = 101,

  // Se ha mandado a revision manual
  IN_REVISION = 6,

  // La foto se muestra correcta, indicando que es un envase valido
  // Esta foto puede dar reciclos, solo depende de los limites semanales
  VALIDATED = 2,
  // Aunque sea un envase valido, no dara reciclos, ya que o bien es duplicada o bien el codigo de barras es repetido
  FAKE_VALIDATED = 7,

  // La foto se muestra erronea indicando queno da reciclos
  ERROR = 4,

  // Cuando se vacia le cubo, estos son los 2 estados finales
  // La foto estaba en estado valido
  SUCCESS = 3,
  // La foto era erronea o fake valid
  DELETED = 5,
}
export enum RECYCLE_PHOTO_STATUS_LABEL {
  PROCESSING = 'Procesando',
  // INCIDENCE_DUPLICATED = 'Incidencia duplicada',
  INCIDENCE = 'Incidencia',
  // INCIDENCE_CAN_OR_BOTTLE = 'Incidencia lata o botella',
  IN_REVISION = 'En revisión',
  VALIDATED = 'Validado',
  FAKE_VALIDATED = 'Falso validado',
  ERROR = 'Error',
  SUCCESS = 'Correcto',
  DELETED = 'Eliminado',
}
export const RECYCLE_PHOTO_STATUS_ARRAY = [
  {id: RECYCLE_PHOTO_STATUS.PROCESSING, label: RECYCLE_PHOTO_STATUS_LABEL.PROCESSING},
  // {id: RECYCLE_PHOTO_STATUS.INCIDENCE_DUPLICATED, label: RECYCLE_PHOTO_STATUS_LABEL.INCIDENCE_DUPLICATED},
  {id: RECYCLE_PHOTO_STATUS.INCIDENCE, label: RECYCLE_PHOTO_STATUS_LABEL.INCIDENCE},
  // {id: RECYCLE_PHOTO_STATUS.INCIDENCE_CAN_OR_BOTTLE, label: RECYCLE_PHOTO_STATUS_LABEL.INCIDENCE_CAN_OR_BOTTLE},
  {id: RECYCLE_PHOTO_STATUS.IN_REVISION, label: RECYCLE_PHOTO_STATUS_LABEL.IN_REVISION},
  {id: RECYCLE_PHOTO_STATUS.VALIDATED, label: RECYCLE_PHOTO_STATUS_LABEL.VALIDATED},
  {id: RECYCLE_PHOTO_STATUS.FAKE_VALIDATED, label: RECYCLE_PHOTO_STATUS_LABEL.FAKE_VALIDATED},
  {id: RECYCLE_PHOTO_STATUS.ERROR, label: RECYCLE_PHOTO_STATUS_LABEL.ERROR},
  {id: RECYCLE_PHOTO_STATUS.SUCCESS, label: RECYCLE_PHOTO_STATUS_LABEL.SUCCESS},
  {id: RECYCLE_PHOTO_STATUS.DELETED, label: RECYCLE_PHOTO_STATUS_LABEL.DELETED},
];

export enum RECYCLE_PHOTO_CLASSIFICATION {
  BOTTLE = 'bottle',
  NOTHING = 'nothing',
  CAN = 'can',
}

export enum DAYS_OF_WEEK {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

// Statistics
export enum STATISTIC_TYPE {
  // User statistics (old ones)
  USER_REGISTERED = 'USER-CREATED',
  USER_NEW = 'USER-NEW',
  USER_VALIDATED = 'USER-VALIDATED',
  USER_DELETED = 'USER-DELETED',

  // User statistics (new ones)

  // (1) Exclusivamente hogar: usuarios registrados n municipios RECICLOS y sin fuera del hogar
  HOGAR_USERS_ONLY = 'HOGAR-USERS-ONLY',
  // (2) Exclusivamente Fuera del Hogar: usuarios que tienen un market place de fuera del hogar sin estar en un municipio RECICLOS
  FUERA_HOGAR_USERS_ONLY = 'FUERA-HOGAR-USERS-ONLY',
  // (3) Usuarios que hogar + fuera del hogar: aquellos que están registrados en un municipio RECICLOS y que además tienen activo un Marketplace en fuera del hogar
  HOGAR_AND_FUERA_HOGAR_USERS = 'HOGAR-AND-FUERA-HOGAR-USERS',
  // (4) Espontáneos: usuarios registrados en municipios no RECICLOS sin un Marketplace en fuera del hogar.
  SPONTANEOUS_USERS = 'SPONTANEOUS-USERS',
  // (6) Total usuarios hogar = 1+ 3
  TOTAL_HOGAR_USERS = 'TOTAL-HOGAR-USERS',
  // (7) Total usuarios fuera hogar = 2 + 3
  TOTAL_FUERA_HOGAR_USERS = 'TOTAL-FUERA-HOGAR-USERS',
  // (5) Usuarios Reciclos = 1+2+3+4
  TOTAL_USERS_HOGAR_FUERA_HOGAR_SPONTANEOUS = 'TOTAL-USERS-HOGAR-FUERA-HOGAR-SPONTANEOUS',

  // Reciclos spent
  RECICLOS_SPENT_CROWD = 'RECICLOS-SPENT-CROWD',
  RECICLOS_SPENT_LOTTERY = 'RECICLOS-SPENT-LOTTERY',
  RECICLOS_SPENT_MICROINCENTIVE = 'RECICLOS-SPENT-MICROINCENTIVE',
  RECICLOS_SPENT_DIRECT_INCENTIVE = 'RECICLOS-SPENT-DIRECT-INCENTIVE',

  // Reciclos obtained hogar
  RECICLOS_OBTAINED_MGM = 'RECICLOS-OBTAINED-MGM',
  RECICLOS_OBTAINED_WELCOME = 'RECICLOS-OBTAINED-WELCOME',
  RECICLOS_OBTAINED_OTHERS = 'RECICLOS-OBTAINED-OTHERS',
  RECICLOS_OBTAINED_PHOTO = 'RECICLOS-OBTAINED-PHOTO',
  //TODO Comment due to Marketplace changes
  // LTV = 'LTV',

  // Reciclos obtained fuera hogar
  RECICLOS_OBTAINED_SMART_BIN = 'RECICLOS-OBTAINED-SMART-BIN',

  // Reciclos obtained by SMART RING
  RECICLOS_OBTAINED_SMART_RING = 'RECICLOS-OBTAINED-SMART-RING',

  // Active users
  MAU = 'MAU',
  WAU = 'WAU',
  DAU = 'DAU',

  // Users statistics from other user statistics
  // REACH = 'REACH',
  RECURRENCE = 'RECURRENCE',
  CHURN = 'CHURN',
  RETENTION = 'RETENTION',
  REACTIVATION = 'REACTIVATION',

  // Not used
  // BUCKET_THROW = 'BUCKET-THROW',
}

export const STATISTICS_RECICLOS_OBTAINED = [
  STATISTIC_TYPE.RECICLOS_OBTAINED_MGM,
  STATISTIC_TYPE.RECICLOS_OBTAINED_WELCOME,
  STATISTIC_TYPE.RECICLOS_OBTAINED_OTHERS,
  STATISTIC_TYPE.RECICLOS_OBTAINED_PHOTO,
  STATISTIC_TYPE.RECICLOS_OBTAINED_SMART_RING,
  STATISTIC_TYPE.RECICLOS_OBTAINED_SMART_BIN,
];

export const STATISTICS_FUERA_HOGAR_AND_HOGAR = [STATISTIC_TYPE.FUERA_HOGAR_USERS_ONLY, STATISTIC_TYPE.TOTAL_FUERA_HOGAR_USERS, STATISTIC_TYPE.HOGAR_AND_FUERA_HOGAR_USERS];

export const STATISTICS_ONLY_FUERA_HOGAR: any = [];

// Statistics fuera hogar
export const STATISTICS_FUERA_HOGAR = STATISTICS_ONLY_FUERA_HOGAR.concat(STATISTICS_FUERA_HOGAR_AND_HOGAR);

// Statistics hogar
export const STATISTICS_HOGAR = Object.values(STATISTIC_TYPE).filter(item => !STATISTICS_ONLY_FUERA_HOGAR.includes(item));

export const STATISTICS_KPMG = (<T extends string[]>(...fields: T) => fields)(
  STATISTIC_TYPE.USER_NEW,
  // STATISTIC_TYPE.REACH,
  STATISTIC_TYPE.RECICLOS_SPENT_CROWD,
  STATISTIC_TYPE.RECICLOS_SPENT_LOTTERY,
  STATISTIC_TYPE.MAU,
  STATISTIC_TYPE.RECURRENCE,
  STATISTIC_TYPE.RECICLOS_SPENT_MICROINCENTIVE
);

export type STATISTICS_KPMG_TYPE = typeof STATISTICS_KPMG[number];

export const STATISTIC_FRACTION_TYPES = [
  //STATISTIC_TYPE.REACH,
  STATISTIC_TYPE.RECURRENCE,
  STATISTIC_TYPE.CHURN,
  STATISTIC_TYPE.RETENTION,
  // STATISTIC_TYPE.LTV,
];

export const STATISTIC_PERCENTAGE_TYPES = [
  // STATISTIC_TYPE.REACH,
  STATISTIC_TYPE.CHURN,
  STATISTIC_TYPE.RETENTION,
  STATISTIC_TYPE.RECURRENCE,
];

export const STATISTIC_IMMUTABLE_ALL_COMMUNITIES = [
  STATISTIC_TYPE.HOGAR_USERS_ONLY,
  STATISTIC_TYPE.FUERA_HOGAR_USERS_ONLY,
  STATISTIC_TYPE.HOGAR_AND_FUERA_HOGAR_USERS,
  STATISTIC_TYPE.SPONTANEOUS_USERS,
];

export const STATISTIC_COMPOUND_ALL_COMMUNITIES = [
  STATISTIC_TYPE.USER_NEW,
  STATISTIC_TYPE.TOTAL_HOGAR_USERS,
  STATISTIC_TYPE.TOTAL_FUERA_HOGAR_USERS,
  STATISTIC_TYPE.TOTAL_USERS_HOGAR_FUERA_HOGAR_SPONTANEOUS,
];

export const STATISTIC_ALL_COMMUNITIES = STATISTIC_IMMUTABLE_ALL_COMMUNITIES.concat(STATISTIC_COMPOUND_ALL_COMMUNITIES);

export const STATISTIC_COMPOUND = [
  // STATISTIC_TYPE.REACH,
  // STATISTIC_TYPE.LTV,
  STATISTIC_TYPE.CHURN,
  STATISTIC_TYPE.RECURRENCE,
  STATISTIC_TYPE.RETENTION,
  STATISTIC_TYPE.REACTIVATION,
];

export const STATISTIC_IMMUTABLE = Object.values(STATISTIC_TYPE).filter(item => !STATISTIC_COMPOUND.includes(item) && !STATISTIC_COMPOUND_ALL_COMMUNITIES.includes(item));

// Statistics combination blacklist

export const STATISTIC_WITHOUT_ACCUMULATED = [
  STATISTIC_TYPE.DAU,
  STATISTIC_TYPE.WAU,
  STATISTIC_TYPE.MAU,
  STATISTIC_TYPE.CHURN,
  STATISTIC_TYPE.RECURRENCE,
  STATISTIC_TYPE.RETENTION,
  STATISTIC_TYPE.REACTIVATION,
];

export const STATISTIC_WITHOUT_WEEK_MONTH_YEAR_VALUE = [
  STATISTIC_TYPE.DAU,
  STATISTIC_TYPE.WAU,
  STATISTIC_TYPE.MAU,
  STATISTIC_TYPE.CHURN,
  STATISTIC_TYPE.RECURRENCE,
  STATISTIC_TYPE.RETENTION,
  STATISTIC_TYPE.REACTIVATION,
];

export const STATISTIC_WITH_LEAVING_USERS = [STATISTIC_TYPE.SPONTANEOUS_USERS, STATISTIC_TYPE.FUERA_HOGAR_USERS_ONLY];

export const STATISTIC_ONLY_FROM_1_JUNE = [STATISTIC_TYPE.CHURN, STATISTIC_TYPE.RETENTION, STATISTIC_TYPE.REACTIVATION];

export const STATISTIC_ACCUMULATIVE_TYPES = Object.values(STATISTIC_TYPE).filter(item => !STATISTIC_FRACTION_TYPES.includes(item));

export enum STATISTIC_GROUP {
  COMMUNITIES_CONSOLIDATED = 'COMMUNITIES_CONSOLIDATED',
  COMMUNITIES_DEVELOPMENT = 'COMMUNITIES_DEVELOPMENT',
  COMMUNITIES_NEW_IMPLEMENTATION = 'COMMUNITIES_NEW_IMPLEMENTATION',
}

export enum STATISTIC_PERIODIC {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

// Community Region Type
export enum COMMUNITY_REGION_TYPE {
  // Comunitats de la vida real
  CITY = 'locality', // Girona, Salt, Valencia
  MANAGEMENT_UNIT = 'administrative_area_level_4', //Unidades de gestión (Mancomunidad Alto Palancia)
  COUNTY = 'administrative_area_level_3', // La Selva, Alt Empordà, Pla de l'estany
  PROVINCE = 'administrative_area_level_2', // Provincia Girona
  AUTONOMOUS_COMMUNITY = 'administrative_area_level_1', // Catalunya, La Rioja, Comunidad Valenciana
  COUNTRY = 'country', // ESPAÑA

  // Comunitats fuera hogar
  SMART_BIN_CHILD = 'smart_bin_child', // Fuera Hogar Metro | Fuera Hogar Ferrocarrils
  SMART_BIN_AREA_3 = 'smart_bin_area_level_3', // Fuera Hogar Catalunya
  SMART_BIN_AREA_2 = 'smart_bin_area_level_2', // Bigger than previous
  SMART_BIN_AREA_1 = 'smart_bin_area_level_1', // Bigger than previous
  SMART_BIN_GOD = 'smart_bin_god', // The Spain of smart bins

  // Comunitats fase per la cecilia
  PHASE = 'phase', // Phase community Igualda 1, Igualada 2
  // Comunitat falsa (per proves, etc.)
  FAKE = 'fake', // Fake community
}

const NO_ORDER_DEFAULT = 999;

export enum LOTTERY_INCENTIVES_ORDER {
  NO_ORDER = NO_ORDER_DEFAULT,

  COMPANY_FUERA_DEL_HOGAR_MONTHLY = 1,
  COMPANY_FUERA_DEL_HOGAR_WEEKLY = 2,
  COMPANY_FUERA_DEL_HOGAR_OTHERS = 3,

  SPAIN_HOGAR_FUERA_DEL_HOGAR_MONTHLY = 4,
  SPAIN_HOGAR_MONTHLY = 5,
  SPAIN_FUERA_DEL_HOGAR_MONTHLY = 6,

  SPAIN_HOGAR_FUERA_DEL_HOGAR_WEEKLY = 7,
  SPAIN_HOGAR_WEEKLY = 8,
  SPAIN_FUERA_DEL_HOGAR_WEEKLY = 9,

  SPAIN_HOGAR_FUERA_DEL_HOGAR_OTHERS = 10,
  SPAIN_HOGAR_OTHERS = 11,
  SPAIN_FUERA_DEL_HOGAR_OTHERS = 12,

  CCAA_HOGAR_FUERA_DEL_HOGAR_MONTHLY = 13,
  CCAA_HOGAR_MONTHLY = 14,
  CCAA_FUERA_DEL_HOGAR_MONTHLY = 15,

  CCAA_HOGAR_FUERA_DEL_HOGAR_WEEKLY = 16,
  CCAA_HOGAR_WEEKLY = 17,
  CCAA_FUERA_DEL_HOGAR_WEEKLY = 18,

  CCAA_HOGAR_FUERA_DEL_HOGAR_OTHERS = 19,
  CCAA_HOGAR_OTHERS = 20,
  CCAA_FUERA_DEL_HOGAR_OTHERS = 21,

  PROVINCE_HOGAR_FUERA_DEL_HOGAR_MONTHLY = 22,
  PROVINCE_HOGAR_MONTHLY = 23,
  PROVINCE_FUERA_DEL_HOGAR_MONTHLY = 24,

  PROVINCE_HOGAR_FUERA_DEL_HOGAR_WEEKLY = 25,
  PROVINCE_HOGAR_WEEKLY = 26,
  PROVINCE_FUERA_DEL_HOGAR_WEEKLY = 27,

  PROVINCE_HOGAR_FUERA_DEL_HOGAR_OTHERS = 28,
  PROVINCE_HOGAR_OTHERS = 29,
  PROVINCE_FUERA_DEL_HOGAR_OTHERS = 30,

  UG_HOGAR_FUERA_DEL_HOGAR_MONTHLY = 31,
  UG_HOGAR_MONTHLY = 32,
  UG_FUERA_DEL_HOGAR_MONTHLY = 33,

  UG_HOGAR_FUERA_DEL_HOGAR_WEEKLY = 34,
  UG_HOGAR_WEEKLY = 35,
  UG_FUERA_DEL_HOGAR_WEEKLY = 36,

  UG_HOGAR_FUERA_DEL_HOGAR_OTHERS = 37,
  UG_HOGAR_OTHERS = 38,
  UG_FUERA_DEL_HOGAR_OTHERS = 39,

  //MU = MANAGEMENT_UNIT
  MU_HOGAR_FUERA_DEL_HOGAR_MONTHLY = 40,
  MU_HOGAR_MONTHLY = 41,
  MU_FUERA_DEL_HOGAR_MONTHLY = 42,

  MU_HOGAR_FUERA_DEL_HOGAR_WEEKLY = 43,
  MU_HOGAR_WEEKLY = 44,
  MU_FUERA_DEL_HOGAR_WEEKLY = 45,

  MU_HOGAR_FUERA_DEL_HOGAR_OTHERS = 46,
  MU_HOGAR_OTHERS = 47,
  MU_FUERA_DEL_HOGAR_OTHERS = 48,

  LOCALITY_HOGAR_FUERA_DEL_HOGAR_MONTHLY = 49,
  LOCALITY_HOGAR_MONTHLY = 50,
  LOCALITY_FUERA_DEL_HOGAR_MONTHLY = 51,

  LOCALITY_HOGAR_FUERA_DEL_HOGAR_WEEKLY = 52,
  LOCALITY_HOGAR_WEEKLY = 53,
  LOCALITY_FUERA_DEL_HOGAR_WEEKLY = 54,

  LOCALITY_HOGAR_FUERA_DEL_HOGAR_OTHERS = 55,
  LOCALITY_HOGAR_OTHERS = 56,
  LOCALITY_FUERA_DEL_HOGAR_OTHERS = 57,
}

export enum DIRECT_INCENTIVES_ORDER {
  NO_ORDER = NO_ORDER_DEFAULT,

  COMPANY_FUERA_DEL_HOGAR = 1,

  SPAIN_HOGAR_FUERA_DEL_HOGAR = 2,
  SPAIN_HOGAR = 3,
  SPAIN_FUERA_DEL_HOGAR = 4,

  CCAA_HOGAR_FUERA_DEL_HOGAR = 5,
  CCAA_HOGAR = 6,
  CCAA_FUERA_DEL_HOGAR = 7,

  PROVINCE_HOGAR_FUERA_DEL_HOGAR = 8,
  PROVINCE_HOGAR = 9,
  PROVINCE_FUERA_DEL_HOGAR = 10,

  UG_HOGAR_FUERA_DEL_HOGAR = 11,
  UG_HOGAR = 12,
  UG_FUERA_DEL_HOGAR = 13,

  MU_HOGAR_FUERA_DEL_HOGAR = 14,
  MU_HOGAR = 15,
  MU_FUERA_DEL_HOGAR = 16,

  LOCALITY_HOGAR_FUERA_DEL_HOGAR = 17,
  LOCALITY_HOGAR = 18,
  LOCALITY_FUERA_DEL_HOGAR = 19,
}

export enum CROWD_INCENTIVES_ORDER {
  NO_ORDER = NO_ORDER_DEFAULT,

  COMPANY_FUERA_DEL_HOGAR_WIN_WIN = 1,
  COMPANY_FUERA_DEL_HOGAR = 2,

  SPAIN_HOGAR_FUERA_DEL_HOGAR_WIN_WIN = 3,
  SPAIN_HOGAR_WIN_WIN = 4,
  SPAIN_FUERA_DEL_HOGAR_WIN_WIN = 5,

  SPAIN_HOGAR_FUERA_DEL_HOGAR = 6,
  SPAIN_HOGAR = 7,
  SPAIN_FUERA_DEL_HOGAR = 8,

  CCAA_HOGAR_FUERA_DEL_HOGAR_WIN_WIN = 9,
  CCAA_HOGAR_WIN_WIN = 10,
  CCAA_FUERA_DEL_HOGAR_WIN_WIN = 11,

  CCAA_HOGAR_FUERA_DEL_HOGAR = 12,
  CCAA_HOGAR = 13,
  CCAA_FUERA_DEL_HOGAR = 14,

  PROVINCE_HOGAR_FUERA_DEL_HOGAR_WIN_WIN = 15,
  PROVINCE_HOGAR_WIN_WIN = 16,
  PROVINCE_FUERA_DEL_HOGAR_WIN_WIN = 17,

  PROVINCE_HOGAR_FUERA_DEL_HOGAR = 18,
  PROVINCE_HOGAR = 19,
  PROVINCE_FUERA_DEL_HOGAR = 20,

  UG_HOGAR_FUERA_DEL_HOGAR_WIN_WIN = 21,
  UG_HOGAR_WIN_WIN = 22,
  UG_FUERA_DEL_HOGAR_WIN_WIN = 23,

  UG_HOGAR_FUERA_DEL_HOGAR = 24,
  UG_HOGAR = 25,
  UG_FUERA_DEL_HOGAR = 26,

  MU_HOGAR_FUERA_DEL_HOGAR_WIN_WIN = 27,
  MU_HOGAR_WIN_WIN = 28,
  MU_FUERA_DEL_HOGAR_WIN_WIN = 29,

  MU_HOGAR_FUERA_DEL_HOGAR = 30,
  MU_HOGAR = 31,
  MU_FUERA_DEL_HOGAR = 32,

  LOCALITY_HOGAR_FUERA_DEL_HOGAR_WIN_WIN = 33,
  LOCALITY_HOGAR_WIN_WIN = 34,
  LOCALITY_FUERA_DEL_HOGAR_WIN_WIN = 35,

  LOCALITY_HOGAR_FUERA_DEL_HOGAR = 36,
  LOCALITY_HOGAR = 37,
  LOCALITY_FUERA_DEL_HOGAR = 38,
}

export enum LOCATION_THROW_TYPE {
  HOGAR = 'HOGAR',
  FUERA_DEL_HOGAR = 'FUERA_DEL_HOGAR',
}

//Incentives duration type
export enum DURATION_TYPE {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  OTHERS = 'OTHERS',
}

export const COMMUNITY_REGION_TYPES_SMART_BIN = [
  COMMUNITY_REGION_TYPE.SMART_BIN_CHILD,
  COMMUNITY_REGION_TYPE.SMART_BIN_AREA_3,
  COMMUNITY_REGION_TYPE.SMART_BIN_AREA_2,
  COMMUNITY_REGION_TYPE.SMART_BIN_AREA_1,
  COMMUNITY_REGION_TYPE.SMART_BIN_GOD,
];

// Community Type
export enum COMMUNITY_TYPE {
  Container = 'Container',
  DoorToDoor = 'DoorToDoor',
  Avant = 'Avant',
}

export enum COMMUNITY_DESTINATION_STATUS {
  CLASSIC_TO_AVANT = 'ClassicUserinsideAvant',
  AVANT_OUTSIDE = 'AvantUserOutsideAvant',
  OUTSIDE_SPAIN = 'OutsideSpain',
  CORRECT_LOCATION = ' CorrectLocation',
}

// Revision Status
export enum REVISION_STATUS {
  CREATED = 3,
  PENDING = 0,
  IN_REVISION = 1,
  REVIEWED = 2,
  DELETED = 4,
}

// Revision Status
export enum DEPOSIT_STATUS {
  IN_REVISION = 0,
  VALID = 1,
  INVALID = -1,
  NOT_APPLICABLE = 2,
  AVANT_IA_PENDING = 10,
  AVANT_IN_REVISION = 11,
  AVANT_VALID = 12,
  AVANT_IA_VALID = 22,
  AVANT_BPO_VALID = 32,
  AVANT_INVALID = 13,
  AVANT_ERROR = 14,
  AVANT_BPO_BLOCKED = 15,
  AVANT_BPO_PROCESSING = 16,
}

// Claim recycle photo result
export enum CLAIM_RECYCLE_PHOTO_RESULT {
  UNASSIGNED_TYPE = 0,
  CORRECT_TYPE_CAN = 1,
  CORRECT_TYPE_BOTTLE = 2,
  INCORRECT_TYPE = 3,
  OTHERS = 4,
}

// Duplicate recycle photo result
export enum DUPLICATE_RECYCLE_PHOTO_RESULT {
  INVALID = -1,
  VALID = 1,
}

//  Door to door deposit photo result
export enum DOOR_TO_DOOR_DEPOSIT_PHOTO_RESULT {
  INVALID = -1,
  VALID = 1,
}

export enum DOOR_TO_DOOR_DEPOSIT_TYPE {
  HOUSE_DOOR_BAG = 'HOUSE_DOOR_BAG',
  HOUSE_DOOR_CUBE = 'HOUSE_DOOR_CUBE',
  ESTABLISHMENT_DOOR_CUBE = 'ESTABLISHMENT_DOOR_CUBE',
  HOOK = 'HOOK',
  INVALID = 'INVALID',
}

export enum THUMBNAIL_SIZE {
  SMALL = '@sm',
  MEDIUM = '@md',
  ORIGINAL = '',
}

export enum BARCODE_TYPE {
  CAN = 'Lata',
  BOTTLE = 'Botella',
  NO_DRINK_BOTTLE = 'Botella no bebida',
  NO_DRINK_CAN = 'Lata no bebida',
  BRICK = 'Brick',
  OTHER = 'Otros',
  PACK = 'Pack',
  PENDING = 'Pendiente',
}

export enum RECYCLE_PHOTO_CATEGORY {
  CAN = 'CAN',
  BOTTLE = 'BOTTLE',
  OTHERS = 'OTHERS',
  UNKNOWN = 'UNKNOWN',
}

// Container type
export enum REGISTER_CONTAINER_TYPE {
  PAPER = 'PAPER',
  ORGANIC = 'ORGANIC',
  REST = 'REST',
  GLASS = 'GLASS',
  PLASTIC = 'PLASTIC',
  NO_RECYCLE = 'NO_RECYCLE',
}

export enum COOKIES_TYPES {
  TECHNICAL = 'FUNCTIONAL',
  PERSONALISATION = 'USER_EXPERIENCE',
  ANALYSIS = 'SOCIAL_NETWORK_AND_PROMO',
  ADVERTISING = 'ADVERTISING',
  BEHAVIOURAL_ADVERTISING = 'BEHAVIOURAL_ADVERTISING',
}

export enum CONTAINER_TYPE {
  COMMON = 'COMMON',
  SMART_BIN = 'SMART_BIN',
  SMART_RING = 'SMART_RING',
  PROMOTION = 'PROMOTION',
  AVANT = 'AVANT',
}

export enum SMART_RING_TYPE {
  QR = 'QR',
  CARD = 'CARD',
}

export enum SMART_RING_PILOT_TYPE {
  PILOTO_1 = 1,
  PILOTO_2 = 2,
}

export const SMART_RING_GENERIC_USER_ID_1 = '0000000000000000';
export const SMART_RING_GENERIC_USER_ID_2 = '999999999999999999';
export const SMART_RING_GENERIC_USER_ID_3 = '999999999999999999999';

export enum CONTAINER_REPORT_TYPE {
  NO_QR = 'NO_QR',
  NEW_LOCATION = 'NEW_LOCATION',
}

export enum CONTAINER_SHAPE {
  PERMANENT = 'PERMANENT',
  FOUR_WHEELS = 'FOUR_WHEELS',
  IGLOO = 'IGLOO',
  CUBO = 'CUBO',
  MAILBOX = 'MAILBOX',
}

export enum CONTAINER_SHAPE_RESULT {
  PERMANENT = 'PERMANENT',
  FOUR_WHEELS = 'FOUR_WHEELS',
  IGLOO = 'IGLOO',
  CUBO = 'CUBO',
  MAILBOX = 'MAILBOX',
  INVALID = 'INVALID',
  OTHER_CONTAINERS = 'OTHER_CONTAINERS',
  MAL_TOMADA = 'MAL_TOMADA',
  FRAUD = 'FRAUD',
}

export enum AVANT_CONTAINER_CLASSIFICATION {
  VALID = 'VALID',
  UNKNOWN = 'UNKNOWN',
  INVALID = 'INVALID',
  ERROR = 'ERROR',
}

export enum OTHER_CONTAINER_TYPE {
  PAPER = 'PAPER',
  ORGANIC = 'ORGANIC',
  GLASS = 'GLASS',
  REST = 'REST',
  OTHERS = 'OTHERS',
}

export enum NOTIFICATION_OBJECT_MODEL {
  Lottery = 'Lottery',
  User = 'User',
}

export enum HOME_STATUS {
  Photo = 'Photo',
  Container = 'Container',
  Spend = 'Spend',
  Invite = 'Invite',
}

export enum MESSAGE_SOURCE {
  Web = 1,
  Email = 2,
  Whatsapp = 3,
  Sms = 4,
}

export enum CONTACT_STATUS {
  Created = 0,
  Pending = 1,
  Read = 2,
  Answered = 3,
  Closed = 4,
}

export enum SMART_BIN_THROW_STATUS {
  Processing = 1,
  Success = 2,
  Error = 3,
  Checking = 4,
  PendingUserValidate = 5,
}
export enum SMART_BIN_THROW_STATUS_LABEL {
  Processing = 'Procesando',
  Success = 'Correcto',
  Error = 'Error',
  Checking = 'Validando',
  PendingUserValidate = 'Pend. de validación de usuario',
}

export const SMART_BIN_THROW_STATUS_ARRAY = [
  {id: SMART_BIN_THROW_STATUS.Processing, label: SMART_BIN_THROW_STATUS_LABEL.Processing},
  {id: SMART_BIN_THROW_STATUS.Success, label: SMART_BIN_THROW_STATUS_LABEL.Success},
  {id: SMART_BIN_THROW_STATUS.Error, label: SMART_BIN_THROW_STATUS_LABEL.Error},
  {id: SMART_BIN_THROW_STATUS.Checking, label: SMART_BIN_THROW_STATUS_LABEL.Checking},
  {id: SMART_BIN_THROW_STATUS.PendingUserValidate, label: SMART_BIN_THROW_STATUS_LABEL.PendingUserValidate},
];

export enum SMART_RING_THROW_REQUEST_STATUS {
  NOT_ASSIGNED = 0,
  PENDING_USER_VALIDATION = 1,
  ASSIGNED = 2,
}

export enum SMART_RING_THROW_PROCESS_RESULT {
  PROCESSING = 1,
  SUCCESS = 2,
  INVALID = 3,
  ALREADY_PROCESSED = 4,
  INVALID_SMART_RING = 5,
  INVALID_CONTAINER = 6,
}

export enum TypeRecycledPackaging {
  SMART_BIN = 'smart_bin',
  DOOR2DOOR = 'door2door',
  HOGAR = 'hogar',
}

export enum EXTERNAL_CALLS_STATUS {
  ERROR = -1,
  PENDING = 0,
  PROCESSING = 1,
  SUCCESS = 2,
  SCRIPT_SUCCESS = 3,
}

export enum PRIZE_ORDER_STATUS {
  Created = 0,
  Pending = 3,
  Rejected = 5,
  Processing = 7,
  Delivered = 10,
  Error = -1,
}

// Micro Incentives
export enum MICRO_INCENTIVE_TYPE {
  CHEST = 'CHEST',
  ROULETTE = 'ROULETTE',
  SCRATCH = 'SCRATCH',
  DIRECT = 'DIRECT',
}

// micro incentive status
export enum MICRO_INCENTIVE_STATUS {
  INACTIVE = 0,
  ACTIVE = 1,
  FINISHED = 2,
}

export enum DIRECT_PRIZE_STATUS {
  INACTIVE = -1,
  COMING_SOON = 0,
  ACTIVE = 1,
  FINISHED = 2,
}

export enum RETREE_DIRECT_PRIZE_STATUS {
  INACTIVE = -1,
  COMING_SOON = 0,
  ACTIVE = 1,
  FINISHED = 2,
}

export enum MICRO_INCENTIVE_CLIENT_STATUS {
  INACTIVE = 0,
  ACTIVE = 1,
  COMING_SOON = 2,
  FINISHED = 3,
}

export enum MICRO_INCENTIVE_PLAY_STATUS {
  ACTIVE = 1,
  FINISHED = 3,
  PLAYED = 4,
}

export enum MICRO_INCENTIVE_ACTIVATION_RULE_TYPE {
  CONTAINER_SCAN = 'CONTAINER_SCAN',
  RECICLOS_SPENT = 'RECICLOS_SPENT',
  RECICLOS_EARNED_WEEKLY = 'RECICLOS_EARNED_WEEKLY',
  SMART_BIN_RECICLOS = 'SMART_BIN_RECICLOS',
  RECICLOS_EARNED_ON_CONTAINER_SCAN = 'RECICLOS_EARNED_ON_CONTAINER_SCAN',
  SUCCESSFULL_PHOTO_SCANS = 'SUCCESSFULL_PHOTO_SCANS',
}

export enum USERS_ACTIONS {
  OPEN_APP = 'OPEN_APP',
  ANSWER_REVIEW = 'ANSWER_REVIEW',
  MGM_SHARE = 'MGM_SHARE',
  CLAIM_PRIZE = 'CLAIM_PRIZE',
  SUCCESS_BUCKET_THROW = 'SUCCESS_BUCKET_THROW',
}

export enum MICRO_INCENTIVE_PENDING_CONDITION_TYPE {
  NO_MORE_PLAYS = 'NO_MORE_PLAYS',
  NO_MORE_WINS = 'NO_MORE_WINS',
  ENOUGHT_BALANCE = 'ENOUGHT_BALANCE',
  MISSING_THROWS = 'MISSING_THROWS',
  MISSING_RECICLOS_TO_SPEND = 'MISSING_RECICLOS_TO_SPEND',
  MISSING_RECICLOS_TO_EARN = 'MISSING_RECICLOS_TO_EARN',
  MISSING_RECICLOS_TO_EARN_SMART_BIN = 'MISSING_RECICLOS_TO_EARN_SMART_BIN',
  MISSING_RECICLOS_TO_EARN_ON_CONTAINER_SCAN = 'MISSING_RECICLOS_TO_EARN_ON_CONTAINER_SCAN',
  MISSING_SUCCESSFULL_PHOTOS_TO_SCAN = 'MISSING_SUCCESSFULL_PHOTOS_TO_SCAN',
}

export enum MICRO_INCENTIVE_COLLISION_RULE_TYPE {
  PRIORITY = 'PRIORITY',
}

export enum MICRO_INCENTIVE_FILTER_TYPE {
  RECICLOS_SPEND_IN_PREVIOUS_WEEKS = 'RECICLOS_SPEND_IN_PREVIOUS_WEEKS',
}

export enum MICRO_INCENTIVE_COLLECT_PRIZE_TYPE {
  COLLECTED = 'COLLECTED',
  PENDING_TO_BE_COLLECTED = 'PENDING_TO_BE_COLLECTED',
  PRIZE_CODE_NOT_EXISTS = 'PRIZE_CODE_NOT_EXISTS',
  INCORRECT_COLLECTION_POINT = 'INCORRECT_COLLECTION_POINT',
  COLLECTION_DATE_EXCEEDED = 'COLLECTION_DATE_EXCEEDED',
}

export enum MICRO_INCENTIVE_REVISION_STATUS {
  PENDING = 1,
  REVIEWED = 2,
}

export enum DIRECT_PRIZE_REVISION_STATUS {
  PENDING = 1,
  REVIEWED = 2,
}

export enum RETREE_DIRECT_PRIZE_PARTICIPATION_REVISION_STATUS {
  PENDING = 1,
  VALID = 2,
  INVALID = 3,
}

export enum DIRECT_PRIZE_REVISION_INCIDENCE {
  PENDING = 1,
  WITHOUT = 2,
  WITH = 3,
}

export enum MICRO_INCENTIVE_REVISION_INCIDENCE {
  PENDING = 1,
  WITHOUT = 2,
  WITH = 3,
}

export enum MICRO_INCENTIVE_REVISION_RESULT {
  INVALID = -1,
  VALID = 1,
}

export enum PRIZE_REVISION_STATUS {
  PENDING = 1,
  SUCCESS = 2,
  FAILED = 3,
  NOT_REVIEWED = 4,
}

// Direct Incentive
export enum DIRECT_INCENTIVE_STATUS {
  INACTIVE = 0,
  ACTIVE = 1,
  COMING_SOON = 2,
  FINISHED = 3,
}

export enum COMMUNICATION_TYPE {
  // Customizable
  INFORMATION_OF_INTEREST_RECICLOS = 'informationOfInterestRECICLOS',
  PROMOTIONS = 'promotions',
  NEWS = 'news',
  OTHER = 'other',
  INFORMATION_OF_INTEREST_ECOEMBES = 'informationOfInterestEcoembes',
  // Internal
  ACCOUNT_MANAGEMENT = 'accountManagement', // basica
  POLICY_DEFAULT = 'policyDefault',
}

export enum COMMUNICATION_CHANNEL {
  Internal = 'internal',
  Push = 'push',
  Sms = 'sms',
  Email = 'email',
}

export enum CircularChainCheckerType {
  User = 'User',
  Incentive = 'Incentive',
  Transaction = 'Transaction',
  Balances = 'Balances',
}

export enum PROMOTIONAL_CODE_STATUS {
  CREATED = 1,
  ACTIVE = 2,
  STOPPED = 3,
  FINISHED = 4,
  DELETED = 5,
}

/**
 * 4. Errors
 */
export enum CLIENT_ERROR_CODES {
  BASE_CLIENT = 300,
  VALIDATION = 301,
  CREDENTIALS = 302,
  VERIFICATION = 303,
  BALANCE = 304,
  INTERNAL = 305,
  SMS = 306,
  NOT_FOUND = 307,
  NOT_ALLOWED = 308,
  MONGO_ID = 309,
  STATISTICS = 310,
  CIRCULAR_CHAIN = 311,
  OAUTH2 = 312,
  CONTAINER_BAD_LOCATION = 413,
  UNAUTHORIZED = 401,
  LOGOUT = 402,
  MAINTENANCE = 403,
  MISSING_USER_LOCATION = 421,
  MOLECULER_VALIDATION = 422,
  FORCE_UPGRADE = 423,
  PROFILING_NOT_ANSWERED = 424,
  CONCURRENCY = 450,
}

export enum CLIENT_ERROR_TYPES {
  BASE_CLIENT = 'BASE_CLIENT',
  MISSING_FILE = 'MISSING_FILE',
  VALIDATION = 'VALIDATION',
  MOLECULER_VALIDATION = 'VALIDATION_ERROR',
  CREDENTIALS = 'CREDENTIALS',
  OAUTH2 = 'OAUTH2',
  ACCESS_TOKEN_EXPIRED = 'ACCESS_TOKEN_EXPIRED',
  REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED',
  INVALID_APPLE_ID = 'INVALID_APPLE_ID',
  BANNED = 'BANNED',
  INTERNAL = 'INTERNAL',
  SMS = 'SMS',
  STATISTICS = 'STATISTICS',
  NOT_FOUND = 'NOT_FOUND',
  NOT_ALLOWED = 'NOT_ALLOWED',
  VERIFICATION = 'VERIFICATION',
  BALANCE = 'BALANCE',
  UNAUTHORIZED = 'UNAUTHORIZED',
  MAINTENANCE = 'MAINTENANCE',
  PROFILING_NOT_ANSWERED = 'PROFILING_NOT_ANSWERED',
  FORCE_UPGRADE = 'FORCE_UPGRADE',
  CIRCULAR_CHAIN = 'CIRCULAR_CHAIN',
  CONTAINER_BAD_LOCATION = 'CONTAINER_BAD_LOCATION',
  CIRCULAR_CHAIN_ERROR = 'CIRCULAR_CHAIN_ERROR',
  CIRCULAR_CHAIN_PROCESSING = 'CIRCULAR_CHAIN_PROCESSING',
  NOT_ENOUGHT_BALANCE = 'NOT_ENOUGHT_BALANCE',
  COMMUNITY_DONT_MATCH = 'COMMUNITY_DONT_MATCH',
  CONDITIONS_NOT_MET = 'CONDITIONS_NOT_MET',
  INVALID_DATES = 'INVALID_DATES',
  COMMUNITY_NOT_EXISTS = 'COMMUNITY_NOT_EXISTS',
  MAX_NUMBER_OF_TICKETS_REACHED = 'MAX_NUMBER_OF_TICKETS_REACHED',
  INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  ALREADY_VALIDATED = 'ALREADY_VALIDATED',
  USER_REGISTERED_WITH_SOCIAL_NETWORK = 'USER_REGISTERED_WITH_SOCIAL_NETWORK',
  INVALID_VERIFY_TOKEN = 'INVALID_VERIFY_TOKEN',
  EXPIRED_VERIFY_TOKEN = 'EXPIRED_VERIFY_TOKEN',
  INVALID_RECOVER_TOKEN = 'INVALID_RECOVER_TOKEN',
  PHONE_EXISTS = 'PHONE_EXISTS',
  PHONE_INVALID = 'PHONE_INVALID',
  PASSWORD_MISSING = 'PASSWORD_MISSING',
  INVALID_BIRTH_DATE = 'INVALID_BIRTH_DATE',
  MINUTES_15_INTERVAL = 'MINUTES_15_INTERVAL',
  USER_NOT_HAVE_PHONE = 'USER_NOT_HAVE_PHONE',
  BUCKET_FULL = 'BUCKET_FULL',
  BUCKET_EMPTY = 'BUCKET_EMPTY',
  PHOTO_IS_NOT_YOURS = 'PHOTO_IS_NOT_YOURS',
  PHOTO_STATUS_CANT_REMOVE = 'PHOTO_STATUS_CANT_REMOVE',
  CANT_SEND_REVISION = 'CANT_SEND_REVISION',
  ALREADY_IN_REVISION = 'ALREADY_IN_REVISION',
  CONTAINER_CODE_INVALID = 'CONTAINER_CODE_INVALID',
  CONTAINER_CODE_INACTIVE = 'CONTAINER_CODE_INACTIVE',
  CONTAINER_LOCATION_NOT_FOUND = 'CONTAINER_LOCATION_NOT_FOUND',
  LOCATION_INVALID = 'LOCATION_INVALID',
  CONTAINER_OUT_OF_SCOPE = 'CONTAINER_OUT_OF_SCOPE',
  STATUS_INVALID = 'STATUS_INVALID',
  ONG_INVALID = 'ONG_INVALID',
  CROWD_INVALID = 'CROWD_INVALID',
  VALUE_TOO_HIGH = 'VALUE_TOO_HIGH',
  CROWD_COMPLETED = 'CROWD_COMPLETED',
  LOTTERY_STATUS_INVALID = 'LOTTERY_TYPE_INVALID',
  MICRO_INCENTIVE_STATUS_INVALID = 'MICRO_INCENTIVE_STATUS_INVALID',
  BARCODE_INVALID = 'BARCODE_INVALID',
  BARCODE_REPEATED = 'BARCODE_REPEATED',
  BAD_INPUT = 'BAD_INPUT',
  LANGUAGE_NOT_SUPPORTED = 'LANGUAGE_NOT_SUPPORTED',
  INVALID_ACCEPT_VALUE = 'INVALID_ACCEPT_VALUE',
  CANT_CLAIM_PRIZE = 'CANT_CLAIM_PRIZE',
  INVALID_THROW_ID = 'INVALID_THROW_ID',
  THROW_ALREADY_REGISTERED = 'THROW_ALREADY_REGISTERED',
  PUSH_TOKEN_INVALID = 'PUSH_TOKEN_INVALID',
  CANT_EDIT_LOTTERY = 'CANT_EDIT_LOTTERY',
  CANT_EDIT_CROWD = 'CANT_EDIT_CROWD',
  CANT_EDIT_MICRO_INCENTIVE = 'CANT_EDIT_MICRO_INCENTIVE',
  CANT_EDIT_USER = 'CANT_EDIT_USER',
  COLLECTION_DATE_EXCEEDED = 'COLLECTION_DATE_EXCEEDED',
  INVALID_COMMUNITY_TYPE = 'INVALID_COMMUNITY_TYPE',
  INVALID_TIME = 'INVALID_TIME',
  PAYLOAD_TOO_LARGE = 'PAYLOAD_TOO_LARGE',
  LOTTERY_NOT_ALLOWED = 'LOTTERY_NOT_ALLOWED',
  MONGO_ID_BAD_FORMAT = 'MONGO_ID_BAD_FORMAT',
  NO_DATA = 'NO_DATA',
  INCOMPATIBLE_PERIOD_METRICS = 'INCOMPATIBLE_PERIOD_METRICS',
  INCOMPATIBLE_ACCUMULATIVE_METRICS = 'INCOMPATIBLE_ACCUMULATIVE_METRICS',
  COMMUNITIES_MUST_BE_FEATURED = 'COMMUNITIES_MUST_BE_FEATURED',
  CONCURRENCY = 'CONCURRENCY',
  POST_USER_INVALID = 'POST_USER_INVALID',
  EXPIRED = 'EXPIRED',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  LIMIT_REACHED = 'LIMIT_REACHED',
  INVALID_SMART_RING_QR = 'INVALID_SMART_RING_QR',
  INVALID_SMART_RING_BLE = 'INVALID_SMART_RING_BLE',
  INVALID_SMART_RING_BLE_ID = 'INVALID_SMART_RING_BLE_ID',
  NOT_INSERTED = 'NOT_INSERTED',
  INCENTIVE_LIMIT_REACHED = 'INCENTIVE_LIMIT_REACHED',
  DIRECT_INCENTIVE_STATUS_INVALID = 'DIRECT_INCENTIVE_STATUS_INVALID',
  CANT_EDIT_DIRECT_INCENTIVE = 'CANT_EDIT_DIRECT_INCENTIVE',
  PROMOTION_OUT_OF_DATE = 'PROMOTION_OUT_OF_DATE',
  PROMOTION_ALREADY_PARTICIPATED = 'PROMOTION_ALREADY_PARTICIPATED',
  PROMOTIONAL_CODE_INVALID = 'PROMOTIONAL_CODE_INVALID',
  PROMOTIONAL_CODE_GENERIC_MESSAGE = 'PROMOTIONAL_CODE_GENERIC_MESSAGE',
  PROMOTIONAL_CODE_MGM_OWNER = 'PROMOTIONAL_CODE_MGM_OWNER',
  VIVA_MARKETING_API_ERROR = 'VIVA_MARKETING_API_ERROR',
  SAME_GROUP_ID_CARD_CLAIM_ERROR = 'SAME_GROUP_ID_CARD_CLAIM_ERROR',
  TREE_NAME_EXISTS = 'TREE_NAME_EXISTS',
  INVALID_AVANT_COMMUNITY = 'INVALID_AVANT_COMMUNITY',
  CURRENT_TREE_IN_PROGRESS = 'CURRENT_TREE_IN_PROGRESS',
  QR_NOT_ENOUGH = 'QR_NOT_ENOUGH',
  CANT_EDIT_CONTAINER = 'CANT_EDIT_CONTAINER',
  CANT_ACTIVATE_CONTAINER = 'CANT_ACTIVATE_CONTAINER',
  EMAIL_EXISTS = 'EMAIL_EXISTS',
  EMAIL_INVALID = 'EMAIL_INVALID',
  CONTAINER_NOT_EXIST = 'CONTAINER_NOT_EXIST',
  CONTAINER_STATUS_CANNOT_BE_UPDATED = 'CONTAINER_STATUS_CANNOT_BE_UPDATED',
  CONTAINER_STATUS_NOT_VALID = 'CONTAINER_STATUS_NOT_VALID',
  MISSING_USER_LOCATION = 'MISSING_USER_LOCATION',
  RECAPTCHA_NEEDED = 'RECAPTCHA_NEEDED',
  RECAPTCHA_MISSING = 'RECAPTCHA_MISSING',
  TRANSACTION_NOT_IN_TREE = 'TRANSACTION_NOT_IN_TREE',
  RETREE_STAGE_NOT_ACTIVE = 'RETREE_STAGE_NOT_ACTIVE',
}

// 500 504 514 Utilitzats per Moleculer
export enum SERVER_ERROR_CODES {
  BASE_SERVER = 600,
  UNEXPECTED = 601,
  SAVE = 602,
  REMOVE = 603,
  NOT_FOUND = 604,
  EXCEPTION = 605,
  INTERNAL_PARAMS = 606,
  UPDATE = 607,
}

export enum HTML_ERROR_CODES {
  BASE_HTML = 500,
  NOT_FOUND = 404,
}

export enum EVENTS_CURRENCY {
  RECICLOS = 'RECICLOS',
  EUR = 'EUR', //ISO 4217 para RECICLOS
}

export enum EVENTS_SPEND_VIRTUAL_CURRENCY {
  MICROINCENTIVE_PLAY = 'MicroIncentivePlay',
  LOTTERY = 'Lottery',
  CROWD = 'Crowd',
}

export enum EVENTS_EARN_VIRTUAL_CURRENCY {
  WELCOME = 'Welcome',
  CLEAR_BUCKET = 'ClearBucket',
  CLEAR_BUCKET_DOOR_TO_DOOR = 'ClearBucketDoorToDoor',
}

export enum EVENTS_LIST_NAME {
  LOTTERY_ACTIVE = 'lottery_active',
  LOTTERY_PENDING = 'lottery_pending',
  LOTTERY_FINISHED = 'lottery_finished',
  CROWD_ACTIVE = 'crowd_active',
  CROWD_COMING_SOON = 'crowd_coming_soon',
  CROWD_FINISHED = 'crowd_finished',
  DIRECT_INCENTIVE_ACTIVE = 'direct_incentive_active',
  DIRECT_INCENTIVE_COMING_SOON = 'direct_incentive_coming_soon',
  DIRECT_INCENTIVE_FINISHED = 'direct_incentive_finished',
  MICRO_INCENTIVE_ACTIVE = 'micro_incentive_active',
  MICRO_INCENTIVE_COMING_SOON = 'micro_incentive_coming_soon',
  MICRO_INCENTIVE_FINISHED = 'micro_incentive_finished',
  DIRECT_PRIZE_ACTIVE = 'micro_incentive_active',
  DIRECT_PRIZE_COMING_SOON = 'micro_incentive_coming_soon',
  DIRECT_PRIZE_FINISHED = 'micro_incentive_finished',
}

export enum EVENTS_DEFAULTS {
  CROWD_PRICE = 1,
}

/**
 * 5. Models Names
 */
export enum MODEL_NAME {
  Archive = 'Archive',
  Tree = 'Tree',
  TreeImpact = 'TreeImpact',
  UserActions = 'UserActions',
  Barcode = 'Barcode',
  BucketThrow = 'BucketThrow',
  Campaign = 'Campaign',
  CampaignTracking = 'CampaignTracking',
  CircularChainChecker = 'CircularChainChecker',
  CircularChainCalls = 'CircularChainCalls',
  CircularChainQueue = 'CircularChainQueue',
  ClaimRecyclePhoto = 'ClaimRecyclePhoto',
  CollectionPoint = 'CollectionPoint',
  Community = 'Community',
  CommunityGroup = 'CommunityGroup',
  Concurrency = 'Concurrency',
  ConfigurationConstants = 'ConfigurationConstants',
  Contact = 'Contact',
  ContainerLocation = 'ContainerLocation',
  ContainerReport = 'ContainerReport',
  Container = 'Container',
  Counter = 'Counter',
  Crowd = 'Crowd',
  CustomerJourney = 'CustomerJourney',
  DirectPrizes = 'DirectPrizes',
  RetreeDirectPrize = 'RetreeDirectPrize',
  RetreeDirectPrizeParticipation = 'RetreeDirectPrizeParticipation',
  DirectPrizeParticipations = 'DirectPrizeParticipations',
  DuplicateRecyclePhoto = 'DuplicateRecyclePhoto',
  DynamicConstants = 'DynamicConstants',
  ExternalCalls = 'ExternalCalls',
  ExternalCallsLimitsControl = 'ExternalCallsLimitsControl',
  ExternalCallsErrorsControl = 'ExternalCallsErrorsControl',
  ExternalCallsToken = 'ExternalCallsToken',
  File = 'File',
  LotteryNumber = 'LotteryNumber',
  LotteryNumberOld = 'LotteryNumberOld',
  Lottery = 'Lottery',
  MicroIncentivePlay = 'MicroIncentivePlay',
  MicroIncentive = 'MicroIncentive',
  Modal = 'Modal',
  DirectIncentive = 'DirectIncentive',
  Notification = 'Notification',
  Ong = 'Ong',
  Post = 'Post',
  PostUser = 'PostUser',
  Prizes = 'Prizes',
  Reciclos = 'Reciclos',
  RecyclePhoto = 'RecyclePhoto',
  RecyclePhotoOld = 'RecyclePhotoOld',
  Report = 'Report',
  RefreshTokens = 'RefreshTokens',
  SmartBinContainer = 'SmartBinContainer',
  SmartRingContainer = 'SmartRingContainer',
  SmartBinThrow = 'SmartBinThrow',
  SmartRingThrow = 'SmartRingThrow',
  SmartRingThrowRequest = 'SmartRingThrowRequest',
  Statistic = 'Statistic',
  StatisticGroup = 'StatisticGroup',
  Transaction = 'Transaction',
  Url = 'Url',
  UserOld = 'UserOld',
  User = 'User',
  Process = 'Process',
  PrizeCode = 'PrizeCode',
  Promotion = 'Promotion',
  PromotionalCode = 'PromotionalCode',
  PromotionalCodeMessages = 'PromotionalCodeMessages',
  PromotionalCodeExchange = 'PromotionalCodeExchange',
  IncentivePrizeCode = 'IncentivePrizeCode',
  Polygon = 'Polygon',
  FeatureFlag = 'FeatureFlag',
  OrderToWork = 'OrderToWork',
  Combo = 'Combo',
  ContainerPhotos = 'ContainerPhotos',
  TransactionsMerkleTree = 'TransactionsMerkleTree',
  TransactionHashes = 'TransactionHashes',
  UserBlocked = 'UserBlocked',
  RfmHistory = 'RfmHistory',
  AiRecyclingPoint = 'AiRecyclingPoint',
  BPORecyclingPoint = 'BPORecyclingPoint',
  LastExecution = 'LastExecution',
  LegalTexts = 'LegalTexts',
  AcceptedLegalTexts = 'AcceptedLegalTexts',
  UsersBanned = 'UsersBanned',
  PromotionTemplate = 'PromotionTemplate',
  PackagingMerkleTree = 'PackagingMerkleTree',
  PackagingHashes = 'PackagingHashes',
}

export enum MODEL_COLLECTION {
  Crowd = 'crowds',
  BucketThrow = 'bucket_throw',
  MicroIncentive = 'micro_incentives',
  Community = 'community',
  LotteryNumber = 'lotteries_numbers',
  LotteryNumberOld = 'lotteries_numbers_old',
  Transaction = 'transaction',
  Lottery = 'lotteries',
  User = 'users',
  Statistics = 'statistics_new',
  SmartBinContainer = 'smart_bin_containers',
  RecyclePhoto = 'recycle_photo',
  RecyclePhotoOld = 'recycle_photo_old',
  SmartRingContainer = 'smart_ring_containers',
  DirectIncentive = 'direct_incentives',
  ContainerLocation = 'container_locations',
  OrderToWork = 'order_to_work',
  Combo = 'combos',
  ContainerPhotos = 'container_photos',
  DirectPrizes = 'direct_prizes',
  RetreeDirectPrize = 'retree_direct_prize',
  RetreeDirectPrizeParticipation = 'retree_direct_prize_participation',
  LegalTexts = 'legal_texts',
  AcceptedLegalTexts = 'accepted_legal_texts',
}

//Insert, Update or Delete container locations
export enum ResultInsertOrUpdate {
  Inserted = 'Inserted',
  Updated = 'Updated',
}

export enum RESULT_DELETE {
  DELETED = 'Deleted',
  NOT_DELETED = 'NotDeleted',
}

//Incentives duration type
export enum DIRECT_INCENTIVES_REWARD_TYPE {
  PRIZE = 'PRIZE',
  EXCHANGE = 'EXCHANGE',
}

export enum TRANSACTION_FILTER {
  ALL = 'all',
  GENERATED = 'generated',
  SPENT = 'spent',
}

export enum ACTION_COUNTER_TYPE {
  OPEN_APP = 'OPEN_APP',
  MGM_SHARE = 'MGM_SHARE',
  CLAIM_PRIZE = 'CLAIM_PRIZE',
  CROWD_DONATION = 'CROWD_DONATION',
  GET_DIRECT_INCENTIVE = 'GET_DIRECT_INCENTIVE',
}

export enum PROMOTIONAL_CODE_TYPE {
  MGM = 'MGM',
  DIRECT = 'DIRECT',
  INDIRECT = 'INDIRECT',
}

export enum REFERRED_USER_ACTION {
  ALL = 'ALL',
  REGISTERED = 'REGISTERED',
  BUCKET_THROWN = 'BUCKET_THROWN',
}

export enum QUEUE_PRIORITY {
  LOW = 0,
  MEDIUM = 1,
  HIGH = 2,
}

type PriorityByTransactionType = {
  [key in TRANSACTION_TYPE]: QUEUE_PRIORITY;
};

export const PriorityByTransaction: PriorityByTransactionType = {
  // LOW
  [TRANSACTION_TYPE.CROWD_TO_ARCHIVE]: QUEUE_PRIORITY.LOW,
  [TRANSACTION_TYPE.LOTTERY_TO_ARCHIVE]: QUEUE_PRIORITY.LOW,
  [TRANSACTION_TYPE.LOTTERY_WINNER_TO_ARCHIVE]: QUEUE_PRIORITY.LOW,
  [TRANSACTION_TYPE.MICRO_INCENTIVE_TO_ARCHIVE]: QUEUE_PRIORITY.LOW,
  [TRANSACTION_TYPE.DIRECT_INCENTIVE_TO_ARCHIVE]: QUEUE_PRIORITY.LOW,
  [TRANSACTION_TYPE.RECICLOS_EXPIRATION]: QUEUE_PRIORITY.LOW,
  [TRANSACTION_TYPE.RECICLOS_RECTIFICATION]: QUEUE_PRIORITY.LOW,
  [TRANSACTION_TYPE.RECICLOS_EXPIRATION_ARCHIVE]: QUEUE_PRIORITY.LOW,

  // MEDIUM
  [TRANSACTION_TYPE.LOTTERY_TO_USER]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.FREE_RETREE_RECICLOS]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.RETREE_PLANT]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.RETREE_FERTILIZE]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.RETREE_WATER]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.RETREE_TRANSPLANT]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.WIN_RECICLONIC]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.BIN_TO_CROWD]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.MINT_BIN]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.LOTTERY_SECOND_INITIAL]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.LOTTERY_SECOND_WIN]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.DONATION_ONG]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.JACKPOT_INITIAL]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.JACKPOT_WIN]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.TRANSFER]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.SCAN]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.VALIDATION]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.WELCOME]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.MEMBER_GET_MEMBER]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.SMART_BIN_THROW]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.MINT_PHOTO]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.CIRCULAR_CHAIN_PHOTO]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.BUCKET_THROW]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.PHOTO]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.RECICLOS_GENESIS]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.POLL_REWARD]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.MINT]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.BUY_NUMBERS]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.FREE_NUMBERS]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.REFOUND_TICKET]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.DONATION_CROWD]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.DONATION_PROMOTION_CROWD]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.SMART_BIN_DONATION_MINT]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.MICRO_INCENTIVE_PLAY]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.BUY_DIRECT_INCENTIVE]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.TEMPORAL]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.ROLLBACK_TO_ARCHIVE]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.ASSIGN_RECICLOS_FREE_TICKET]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.SMART_RING_THROW]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.SMART_RING_DONATION_MINT]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.FUERA_HOGAR_DONATION_CROWD]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.HOGAR_DONATION_CROWD]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.RECICLOS_GIFT]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.COMERCIAL_ACTION]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.MEMBER_GET_MEMBER_RECYCLED]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.COMMUNITY_DONATION]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.PROMOTIONAL_CODE_EXCHANGE]: QUEUE_PRIORITY.MEDIUM,
  [TRANSACTION_TYPE.PROMOTIONAL_CODE_EXCHANGE_LIST]: QUEUE_PRIORITY.MEDIUM,
};

export enum FEATURE_FLAG {
  HOME_ICON_MORE_RECICLOS = 'HOME_ICON_MORE_RECICLOS',
  HIDE_RECICLOS_LIMIT_POPUP = 'HIDE_RECICLOS_LIMIT_POPUP',
  RECICLOS_LIMIT_POPUP_WITH_DATES = 'RECICLOS_LIMIT_POPUP_WITH_DATES',
  OLD_BALANCE_POPUP = 'OLD_BALANCE_POPUP',
  NEW_BALANCE_POPUP_WITH_DATES = 'NEW_BALANCE_POPUP_WITH_DATES',
  LATAS_DOBLE_RECICLOS = 'LATAS_DOBLE_RECICLOS',
  BOTELLAS_DOBLE_RECICLOS = 'BOTELLAS_DOBLE_RECICLOS',
  EXPIRATION_POPUP_WITH_DATES = 'EXPIRATION_POPUP_WITH_DATES',
  RETREE_COMMUNITY = 'RETREE_COMMUNITY',
  COMMUNITY_AVANT = 'COMMUNITY_AVANT',
  PILOTO3_SMARTRING_SB = 'PILOTO3_SMARTRING_SB',
  CLIENT_GENERIC_CONFIG = 'CLIENT_GENERIC_CONFIG',
  NPS_REVIEW_CONFIG = 'NPS_REVIEW_CONFIG',
  CONFIRM_IMAGE_MODAL_AVANT = 'CONFIRM_IMAGE_MODAL_AVANT',
  CONFIRM_IMAGE_MODAL_DOOR_TO_DOOR = 'CONFIRM_IMAGE_MODAL_DOOR_TO_DOOR',
  CIRCULAR_TRUST_IS_ACTIVE = 'CIRCULAR_TRUST_IS_ACTIVE',
  QUEUE_IT_CONFIG = 'QUEUE_IT_CONFIG',
  RETREE_DIRECT_PRIZE = 'RETREE_DIRECT_PRIZE',
}

export enum FEATURE_FLAG_TYPE {
  USER_REGISTERED_COMMUNITY = 'USER_REGISTERED_COMMUNITY',
  START_OF_OXIDATION_MONTH = 'START_OF_OXIDATION_MONTH',
  DATES = 'DATES',
  CLIENT_CONFIG = 'CLIENT_CONFIG',
}

export enum USER_NPS_REVIEW {
  GOOD = 'GOOD',
  REGULAR = 'REGULAR',
  BAD = 'BAD',
  NONE = 'NONE',
}

export enum USER_NPS_BAD_OPTIONS {
  NOT_RECEIVED = 'NOT_RECEIVED',
  CANS_NOT_ACCEPTED = 'NOT_ACCEPTED',
  QR_DIFFICULT_TO_READ = 'QR_DIFFICULT_TO_READ',
  CONTAINER_DIFFICULT_TO_RECOGNIZE = 'CONTAINER_DIFFICULT_TO_RECOGNIZE',
  OTHERS = 'OTHERS',
  NONE = 'NONE',
}

export enum CLOSE_POPUP_OPTIONS {
  OF_COURSE_BUTTON = 'BOTON_POR_SUPUESTO',
  SEND_BUTTON = 'BOTON_ENVIAR',
  CLOSE_BUTTON = 'BOTON_CERRAR',
  CLOSE_ICON = 'ICONO_CERRAR',
  OTHER_MOMENT_BUTTON = 'BOTON_OTRO_MOMENTO',
  SKIP_BUTTON = 'BOTON_OMITIR',
}

export enum SMART_RING_ACTIVE_FILTER {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ALL = 'ALL',
}

export enum COMBO {
  CONTAINER_STATUS = 'CONTAINER_STATUS',
  INCIDENTS = 'INCIDENTS',
}

export enum PROMOTIONAL_CODE_STATUS_FILTER {
  ALL = 'TODOS',
  CREATED = 'CREADO',
  ACTIVE = 'ACTIVO',
  STOPPED = 'PARADO',
  FINISHED = 'FINALIZADO',
  DELETED = 'ELIMINADO',
}

export enum PROMOTIONAL_CODE_STATUS_MESSAGES {
  SUCCESS = 'appliedCorrectly',
  UNVALID = 'unvalid',
  OUTDATED = 'outdated',
  NON_ACCUMULATIVE = 'nonAccumulative',
}

// HUPI Images types

export enum HUPI_TYPE_IMAGE {
  CAN = 'CAN',
  BOTTLE = 'BOTTLE',
  INVALID = 'INVALID',
  FRAUD = 'FRAUD',
}

export const BACKOFFICE_MAX_RECICLOS_TO_GIVE = 3000;

export enum SCORING_SOURCE {
  AI = 'AI',
  BPO = 'BPO',
  CROWDSENSING = 'CROWDSENSING',
}

export enum AVANT_VALID_THROW_TYPE {
  AVANT_SCORING = 'AVANT_SCORING',
  AVANT_IA = 'AVANT_IA',
}

export enum FIELDS_BARCODE_FORM {
  ID = 'id',
  PACKAGE_TYPE = 'packageType',
  CODE = 'code',
}

export enum LEGAL_TEXT_CATEGORY {
  TC = 'TC',
  PP = 'PP',
  RETREE_DIRECT_PRIZE = 'RETREE_DIRECT_PRIZE',
}

export enum COMMUNITY_TYPE_LEGAL_TEXT {
  AVANT = 'Avant',
  NO_AVANT = 'No_Avant',
  COMMON = 'Common',
}

export enum MERKLE_TREE_SEND_STATUS {
  ERROR = -1,
  PENDING = 0,
  PROCESSING = 1,
  SUCCESS = 2,
}
