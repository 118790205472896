import {IBase} from './ibase';

export enum LAST_EXECUTION_TYPE {
  EXTERNAL_CALL = 'EXTERNAL_CALL',
  CRON = 'CRON',
}

export type ILastExecution = IBase & {
  processName: string;
  type: LAST_EXECUTION_TYPE;
  lastSuccessfulExecutionDate: Date;
  lastErrorExecutionDate: Date;
  data: any;
};
