import {ILanguage} from '../constants';
import {IIncentive, IIncentiveHomeFeatured} from './iincentive';

export const CROWD_SIMPLE_FIELDS = (<T extends string[]>(...fields: T) => fields)(
  '_id',
  'title',
  'balance',
  'goal',
  'images',
  'imagesBlurhash',
  'secondaryImage',
  'secondaryImageBlurhash',
  'balanceFinished',
  'status',
  'homeScreenIcon',
  'popupImage',
  'popupImageExpanded',
  'group',
  'homeScreenPriority',
  'homeScreenTitle',
  'homeScreenSubtitle',
  'homeScreenDescription',
  'homeScreenAction'
);

export const CROWD_SIMPLE_FIELDS_PDF = (<T extends string[]>(...fields: T) => fields)('_id', 'communities', 'title', 'balance', 'goal', 'end', 'start', 'finishDate');

export const CROWD_SIMPLE_FIELDS_LOGIC_LIST = (<T extends string[]>(...fields: T) => fields)(
  '_id',
  'title',
  'balance',
  'balanceFinished',
  'goal',
  'images',
  'imagesBlurhash',
  'status',
  'communities',
  'group'
);

export type ICrowdSimple = Pick<ICrowd, typeof CROWD_SIMPLE_FIELDS[number]> & {image: string; imageBlurhash: string};
export type ICrowdSimpleList = Omit<Pick<ICrowd, typeof CROWD_SIMPLE_FIELDS_LOGIC_LIST[number]>, 'images' | 'imagesBlurhash' | 'balanceFinished'> & {
  image: string;
  imageBlurhash: string;
};

export type ICrowdSimplePdf = Pick<ICrowd, typeof CROWD_SIMPLE_FIELDS_PDF[number]>;

export type ICrowd<P extends string = '_'> = IIncentive<P> &
  IIncentiveHomeFeatured & {
    title: ILanguage;
    shortDescription: ILanguage;
    description: ILanguage;
    status: number;
    balanceFinished: number;
    goal: number;
    finishDate?: string | Date;
    images: string[];
    imagesBlurhash?: string[];
    secondaryImage?: string;
    secondaryImageBlurhash?: string;
    flag: string;
    showDates: boolean;
    avoidPostGeneration: boolean;
    hidden: boolean;
    winwin: boolean;
    vmId?: string;
    queueable?: boolean;
  };
