import {CLIENT_ERROR_CODES, CLIENT_ERROR_TYPES} from '../../constants';

export class BaseClientError extends Error {
  code: CLIENT_ERROR_CODES;
  type: CLIENT_ERROR_TYPES;
  data: any;
  retryable: boolean;
  mute = false;

  constructor(code: CLIENT_ERROR_CODES = CLIENT_ERROR_CODES.BASE_CLIENT, type: CLIENT_ERROR_TYPES = CLIENT_ERROR_TYPES.BASE_CLIENT, data?: any) {
    super('Client Controlled Error');
    this.data = data;
    this.code = code;
    this.type = type;
  }

  setMute(mute: boolean) {
    this.mute = mute;
  }
}
