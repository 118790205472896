import {IBase} from './ibase';

export enum FINISH_REASON {
  InfiniteLoop = 'InfiniteLoop',
  ForceStop = 'ForceStop',
  AllDone = 'AllDone',
  Error = 'Error',
}

export type IProcess<P extends string = '_'> = IBase & {
  name: string;
  running: boolean;
  forceStop: boolean;
  start: string | Date;
  executionLogs: {
    info: string[];
    debug: string[];
    error: string[];
  };
  executionReport: {
    processed: number;
    max: number;
  };
  executionError?: any;
  finishDate?: string | Date;
  finishReason?: FINISH_REASON;
};
