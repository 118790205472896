export type Coords = {lat: number; lon: number; mocked: boolean};
export type Location = {community: string; mocked: boolean};

export const POSITION_HEADER = 'Reciclos-Position';
export const LOCATION_HEADER = 'Reciclos-Location';
export const LOCATION_SET_HEADER = 'Reciclos-Set-Location';
export const COMMUNITY_NAME_SET_HEADER = 'Reciclos-Set-Community-Name';

export const parseRPosition = (position: string): Coords => {
  try {
    const parts = position.split(';');
    const lat = parseFloat(parts[0]);
    const lon = parseFloat(parts[1]);
    const mocked = parts[2] === 'true';
    return {lat, lon, mocked};
  } catch (e) {
    return {lat: -1, lon: -1, mocked: false};
  }
};

export const parseRLocation = (location: string): Location => {
  try {
    const parts = location.split(';');
    const community = parts[0];
    const mocked = parts[1] === 'true';
    return {community, mocked};
  } catch (e) {
    return {community: '', mocked: false};
  }
};

export const buildRPosition = (position: Coords): string => position.lat + ';' + position.lon + ';' + position.mocked;

export const buildRLocation = (location: Location): string => location.community + ';' + location.mocked;
