import {CIRCULAR_CHAIN_ACTION_STATUS, CIRCULAR_CHAIN_ACTIONS} from '../constants';
import {IBase} from './ibase';

export enum TransactionStatus {
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
  NOT_FOUND = 'NOT_FOUND',
  ALREADY_COMPLETED = 'ALREADY_COMPLETED',
  ALREADY_REJECTED = 'ALREADY_REJECTED',
}

export enum CIRCULAR_CHAIN_ERROR_CODES {
  // Errors from our server
  BAD_REQUEST = 300,
  TIMEOUT = 301,
  SERVICE_UNAVAILABLE = 302,
  MVCC_READ_CONFLICT = 303,
  PEER_NOT_CONNECTED = 304,
  PARSING_ERROR = 305,
  PHANTOM_READ = 306,
  UNEXPECTED = 307,

  // Errors from balance chaincode
  BALANCE_PARAMETERS_VALIDATION = 501,
  BALANCE_PARAMETERS_ARRAY_PARSE = 502,
  BALANCE_DUPLICATED = 503,
  BALANCE_INSUFICIENT_FUNDS = 504,

  // Errors from sorteo chaincode
  SORTEO_PARAMETERS_VALIDATION = 401,
  SORTEO_PARAMETERS_ARRAY_PARSE = 402,
  SORTEO_DUPLICATED = 403,
  SORTEO_INSUFICIENT_FUNDS = 404,
  SORTEO_ALREADY_FINISHED = 405,
  SORTEO_MAX_LOOP = 406,
  SORTEO_INVOKE_CHAINCODE = 407,
}

export const CIRCULAR_CHAIN_ERROR_RETRY = [CIRCULAR_CHAIN_ERROR_CODES.TIMEOUT, CIRCULAR_CHAIN_ERROR_CODES.SERVICE_UNAVAILABLE];

export type ChaincodeError = {
  code: CIRCULAR_CHAIN_ERROR_CODES;
  message: string;
  data?: any;
};

export type ICircularChainPetitionSuccess<R = any> = {
  txId: string;
  time: number;
  created: string | Date;
  success: true;
  status: TransactionStatus;
  data: R;
};

export type ICircularChainPetitionError = {
  txId: string;
  time: number;
  created: string | Date;
  success: false;
  status: TransactionStatus;
  data: ChaincodeError;
};

export type ICircularChainPetition<R = any> = ICircularChainPetitionSuccess<R> | ICircularChainPetitionError;

export type IChainCode = {
  identifier: string;
  method: string;
  params: string[];
};

export type ICircularChainCall<P extends string = '_'> = IBase & {
  uuid: string;
  name: CIRCULAR_CHAIN_ACTIONS;
  status: CIRCULAR_CHAIN_ACTION_STATUS;
  runHooks: boolean;
  chaincode: IChainCode;
  params?: Record<string, any>;
  petitions: ICircularChainPetition[];
  queued: string | Date;
  lockKey?: string;
};
