import {IBase} from './ibase';
import {USER_NPS_REVIEW, USERS_ACTIONS, USER_NPS_BAD_OPTIONS, CLOSE_POPUP_OPTIONS, CONTAINER_TYPE, AVANT_VALID_THROW_TYPE} from '../constants';

export type DataByAction = {
  [USERS_ACTIONS.ANSWER_REVIEW]: string;

  // App review counters
  [USERS_ACTIONS.OPEN_APP]: number;
  [USERS_ACTIONS.MGM_SHARE]: number;
  [USERS_ACTIONS.CLAIM_PRIZE]: number;
  [USERS_ACTIONS.SUCCESS_BUCKET_THROW]: {
    userSatisfaction: USER_NPS_REVIEW;
    userOptionClosePopup?: CLOSE_POPUP_OPTIONS;
    userBadOption?: USER_NPS_BAD_OPTIONS;
    message?: string;
    throwAction?: CONTAINER_TYPE;
    avantType?: AVANT_VALID_THROW_TYPE;
  };
};

export type IUserActions<P extends string = '_'> = IBase & {
  user: string;
  action: USERS_ACTIONS;
  data?: DataByAction[USERS_ACTIONS];
};
