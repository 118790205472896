import {CONTAINER_TYPE, USER_NPS_REVIEW} from '../constants';

export type INPSReviewConfig = {
  [CONTAINER_TYPE.AVANT]: {
    enabled: boolean;
    [USER_NPS_REVIEW.GOOD]: number;
    [USER_NPS_REVIEW.REGULAR]: number;
    [USER_NPS_REVIEW.BAD]: number;
  };
  [CONTAINER_TYPE.COMMON]: {
    enabled: boolean;
    [USER_NPS_REVIEW.GOOD]: number;
    [USER_NPS_REVIEW.REGULAR]: number;
    [USER_NPS_REVIEW.BAD]: number;
  };
  [CONTAINER_TYPE.SMART_BIN]: {
    enabled: boolean;
    [USER_NPS_REVIEW.GOOD]: number;
    [USER_NPS_REVIEW.REGULAR]: number;
    [USER_NPS_REVIEW.BAD]: number;
  };
};

export type IQueueItConfig = {
  clientId: string;
  isTest: boolean;
  queues: {
    main: string;
    scanner: string;
  };
};

export type ILocationWatcher = {
  intervalUnits: string;
  intervalValue: number;
  accuracy: number;
};

export type ICloseIconTimeOut = {
  container: number;
  reciclito: number;
};

export type IRecaptchaConfig = {
  hostname: string;
  key: string;
  keyV2: string;
};

export type IClientGenericConfig = {
  isTesting: boolean;
  attentionEmail: string;
  androidScanditApiKey: string;
  iOSScanditApiKey: string;
  notificationsDaysCountDown: number;
  locationWatcher: ILocationWatcher;
  closeIconTimeout: ICloseIconTimeOut;
  reCaptcha: IRecaptchaConfig;
};
