import {ICircularChainCall} from './icircular-chain-call';
import {IBase} from './ibase';

export enum CIRCULAR_CHAIN_QUEUE_TYPE {
  Supply = 'Supply',
  Lotteries = 'Lotteries',
}

export type ICircularChainQueue = IBase & {
  type: CIRCULAR_CHAIN_QUEUE_TYPE;
  value: ICircularChainCall;
};
