import {DIRECT_INCENTIVE_STATUS, DURATION_TYPE, ILanguage, PRIZE_TYPE} from '../constants';
import {IIncentive} from './iincentive';

export const DIRECT_INCENTIVE_FIELDS = (<T extends string[]>(...fields: T) => fields)(
  '_id',
  'title',
  'images',
  'imagesBlurhash',
  'secondaryImage',
  'secondaryImageBlurhash',
  'logoImage',
  'cost',
  'numberOfPurchases',
  'status',
  'prizeType',
  'group'
);

export const DIRECT_INCENTIVE_FIELDS_LOGIC_LIST = (<T extends string[]>(...fields: T) => fields)(
  '_id',
  'title',
  'images',
  'imagesBlurhash',
  'numberOfPurchases',
  'status',
  'prizeType',
  'communities',
  'group'
);

export type IDirectIncentiveSimple = Pick<IDirectIncentive, typeof DIRECT_INCENTIVE_FIELDS[number]> & {canBuy: boolean; code: string; image: string; imageBlurhash: string};

export type IDirectIncentiveSimpleList = Omit<Pick<IDirectIncentive, typeof DIRECT_INCENTIVE_FIELDS_LOGIC_LIST[number]>, 'images' | 'imagesBlurhash' | 'numberOfPurchases'> & {
  image: string;
  imageBlurhash: string;
  canBuy: boolean;
  code?: string;
};

export type IDirectIncentive<P extends string = '_'> = IIncentive<P> & {
  // Max number of pourchases
  numberOfPurchases: number;

  // Days to collect or claim
  claimDays: number;
  collectDays: number;

  // Prize Description
  title: ILanguage;
  shortDescription: ILanguage;
  description: ILanguage;
  images: string[];
  imagesBlurhash?: string[];
  secondaryImage?: string;
  secondaryImageBlurhash?: string;
  logoImage?: string;

  // Prize Type
  prizeType: PRIZE_TYPE;

  // Attributes related to stock and cost
  cost: number;
  balanceFinished: number;
  maxStock: number;
  stock: number;
  outOfStockDate: string | Date;

  // Current of status of the incentive
  status: DIRECT_INCENTIVE_STATUS;

  // Cron fields
  flag?: string;
  timeFlag?: Date;

  duration: DURATION_TYPE;
  spendLimitByUser: number;
  vmId?: string;
};
