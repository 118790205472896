import {ICommunity} from './icommunity';
import {IBase} from './ibase';
import {STATISTIC_GROUP, STATISTIC_PERIODIC, STATISTIC_TYPE} from '../constants';

export enum STATISTIC_PARAM_FIELD {
  AVG = 'avg',
  MIN = 'min',
  MAX = 'max',
  STD_DEV_POP = 'stdDevPop',
  STD_DEV_SAMP = 'stdDevSamp',
  VALUE = 'value',
}

type AA = {
  [key in STATISTIC_PARAM_FIELD]?: number;
};

export type IStatisticParam = Omit<
  {
    [key in STATISTIC_PARAM_FIELD]?: number;
  },
  'value'
> & {
  value: number;
};

// export type IStatisticParam = {
//   avg?: number;
//   max?: number;
//   min?: number;
//   stdDevPop?: number;
//   stdDevSamp?: number;
//   value: number;
// };

export type IStatistic<P extends string = '_'> = IBase & {
  type: STATISTIC_TYPE;
  period: STATISTIC_PERIODIC;

  group?: STATISTIC_GROUP;
  groupCommunities?: P extends 'groupCommunities' ? ICommunity[] : string[];
  community?: P extends 'community' ? ICommunity : string;

  day: number;
  week: number;
  month: number;
  year: number;
  start: Date;
  end: Date;

  count: IStatisticParam;
  accumulated: IStatisticParam;
  fakeAccumulated: IStatisticParam;

  fraction?: {
    count: {
      dividend: number; // El de adalt
      divider: number; // El de sota
    };
    accumulated: {
      dividend: number; // El de adalt
      divider: number; // El de sota
    };
    fakeAccumulated: {
      dividend: number; // El de adalt
      divider: number; // El de sota
    };
  };
};
